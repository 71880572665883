import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const loginApi = (authHeader) =>
  axios.post(baseURL + "/auth/token/issue", undefined, {
    headers: {
      Authorization: authHeader,
    },
  });

export const postApi = (url, data, contentType = "application/json") =>
  axios
    .post(baseURL + url, data, {
      headers: {
        "Content-Type": contentType,
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((ex) => {
      if (ex.response.status === 403) {
        window.location = "/login";
      } else {
        throw ex;
      }
    });

export const putApi = (url, data, contentType = "application/json") =>
  axios
    .put(baseURL + url, data, {
      headers: {
        "Content-Type": contentType,
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((ex) => {
      if (ex.response.status === 403) {
        window.location = "/login";
      } else {
        throw ex;
      }
    });

export const getApi = (url, contentType = "application/json") =>
  axios
    .get(baseURL + url, {
      headers: {
        "Content-Type": contentType,
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((ex) => {
      if (ex.response.status === 403) {
        window.location = "/login";
      } else {
        throw ex;
      }
    });

export const deleteApi = (url, params, contentType = "application/json") =>
  axios
    .delete(baseURL + url, {
      headers: {
        "Content-Type": contentType,
        Authorization: localStorage.getItem("token"),
      },
      params: params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((ex) => {
      if (ex.response.status === 403) {
        window.location = "/login";
      } else {
        throw ex;
      }
    });

export const postApiWithoutRedirect = (
  url,
  data,
  contentType = "application/json"
) =>
  axios
    .post(baseURL + url, data, {
      headers: {
        "Content-Type": contentType,
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response.data;
    });

export const getApiWithoutRedirect = (url, contentType = "application/json") =>
  axios
    .get(baseURL + url, {
      headers: {
        "Content-Type": contentType,
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response.data;
    });

export const deleteApiWithoutRedirect = (
  url,
  params,
  contentType = "application/json"
) =>
  axios
    .delete(baseURL + url, {
      headers: {
        "Content-Type": contentType,
        Authorization: localStorage.getItem("token"),
      },
      params: params,
    })
    .then((response) => {
      return response.data;
    });
export const getParamsApi = (url, params, contentType = "application/json") =>
  axios
    .get(baseURL + url, {
      headers: {
        "Content-Type": contentType,
        Authorization: localStorage.getItem("token"),
      },
      params: params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
