import React, { useRef, useState, useEffect } from "react";
import {
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
  UploadOutlined,
  RadiusUprightOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Flex,
  Space,
  Form,
  Input,
  message,
  Button,
  Upload,
  Table,
  Popconfirm,
  Image,
  ColorPicker,
  Select,
  Switch,
  Radio,
  Modal,
  Row,
  Col,
} from "antd";
import { getApi, postApi } from "../services/api.jsx";
import Highlighter from "react-highlight-words";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";

const FormItem = Form.Item;
const { Option } = Select;

const s3BaseURL = process.env.REACT_APP_S3_BASE_URL;

const ChecklistModal = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [iconURL, setIconURL] = useState("");

  const [formItemRequired, setFormItemRequired] = useState({
    badgeId1Required: false,
    badgeId2Required: false,
    badgeId3Required: false,
    badgeId4Required: false,
    badgeId5Required: false,
    badgeId6Required: false,
    badgeId7Required: false,
    badgeId8Required: false,
    badgeId9Required: false,
    badgeId10Required: false,
    description1Required: false,
    description2Required: false,
    description3Required: false,
    description4equired: false,
    description5Required: false,
    description6Required: false,
    description7Required: false,
    description8Required: false,
    description9Required: false,
    description10Required: false,
  });

  useEffect(() => {
    if (props.record) {
      let values = props.record;
      const items = props.record.items.map((item, index) =>
        Object.fromEntries(
          Object.keys(item).map((key) => [`${key}${index + 1}`, item[key]])
        )
      );
      items.forEach((item) => {
        values = { ...values, ...item };
      });
      form.setFieldsValue(values);

      setIconURL(props.record.iconURL);
    }
  }, []);

  const saveCheckList = (values) => {
    let items = Array(10);
    Object.keys(values).map((key, index) => {
      if (values[key]) {
        if (key.startsWith("description")) {
          let index = key.substring(11);
          items[index - 1] = {
            ...items[index - 1],
            description: values[key],
          };
        }
        if (key.startsWith("badgeId")) {
          let index = key.substring(7);
          items[index - 1] = { ...items[index - 1], badgeId: values[key] };
        }
      }
    });
    items = items.filter((item) => item !== undefined);

    if (items.length == 0) {
      Swal.fire({
        title: "Error!",
        text: "Checklist should have at least one item!",
        icon: "error",
      });
    } else {
      const email = localStorage.getItem("userEmail");

      items = items.map((item, index) => {
        return {
          ...item,
          createdBy: email,
          lastUpdatedBy: email,
          itemOrder: index + 1,
        };
      });

      let formValues;
      if (props.record) {
        formValues = {
          ...props.record,
          ...values,
          items: items,
          iconURL: iconURL,
          lastUpdatedBy: email,
        };
      } else {
        formValues = {
          ...values,
          items: items,
          iconURL: iconURL,
          createdBy: email,
          lastUpdatedBy: email,
        };
      }

      setLoading(true);
      postApi("/checklist", formValues)
        .then(() => {
          Swal.fire({
            title: "Success!",
            text: "Checklist Created Successfully!",
            icon: "success",
          });
          props.closeModal();
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "Error creating checklist",
            error,
            icon: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadImage = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      postApi("/file", formData, "multipart/form-data").then((data) => {
        onSuccess(data, file);
      });
      Swal.fire({
        title: "Success!",
        text: `${file.name} uploaded successfully`,
        icon: "success",
      });
    } catch (error) {
      onError(error);
      Swal.fire({
        title: "Error!",
        text: `${file.name} upload failed`,
        icon: "error",
      });
    }
  };

  const handleIconChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        let iconUrl = s3BaseURL + info.file.originFileObj.name;
        setIconURL(iconUrl);
      });
    }
  };

  const handleFormChange = (fieldName, value) => {
    if (fieldName === "description1") {
      setFormItemRequired({
        ...formItemRequired,
        badgeId1Required: value ? true : false,
      });
    } else if (fieldName === "description2") {
      setFormItemRequired({
        ...formItemRequired,
        badgeId2Required: value ? true : false,
      });
    } else if (fieldName === "description3") {
      setFormItemRequired({
        ...formItemRequired,
        badgeId3Required: value ? true : false,
      });
    } else if (fieldName === "description4") {
      setFormItemRequired({
        ...formItemRequired,
        badgeId4Required: value ? true : false,
      });
    } else if (fieldName === "description5") {
      setFormItemRequired({
        ...formItemRequired,
        badgeId5Required: value ? true : false,
      });
    } else if (fieldName === "description6") {
      setFormItemRequired({
        ...formItemRequired,
        badgeId6Required: value ? true : false,
      });
    } else if (fieldName === "description7") {
      setFormItemRequired({
        ...formItemRequired,
        badgeId7Required: value ? true : false,
      });
    } else if (fieldName === "description8") {
      setFormItemRequired({
        ...formItemRequired,
        badgeId8Required: value ? true : false,
      });
    } else if (fieldName === "description9") {
      setFormItemRequired({
        ...formItemRequired,
        badgeId9Required: value ? true : false,
      });
    } else if (fieldName === "description10") {
      setFormItemRequired({
        ...formItemRequired,
        badgeId10Required: value ? true : false,
      });
    } else if (fieldName === "badgeId1") {
      setFormItemRequired({
        ...formItemRequired,
        description1Required: value ? true : false,
      });
    } else if (fieldName === "badgeId2") {
      setFormItemRequired({
        ...formItemRequired,
        description2Required: value ? true : false,
      });
    } else if (fieldName === "badgeId3") {
      setFormItemRequired({
        ...formItemRequired,
        description3Required: value ? true : false,
      });
    } else if (fieldName === "badgeId4") {
      setFormItemRequired({
        ...formItemRequired,
        description4Required: value ? true : false,
      });
    } else if (fieldName === "badgeId5") {
      setFormItemRequired({
        ...formItemRequired,
        description5Required: value ? true : false,
      });
    } else if (fieldName === "badgeId6") {
      setFormItemRequired({
        ...formItemRequired,
        description6Required: value ? true : false,
      });
    } else if (fieldName === "badgeId7") {
      setFormItemRequired({
        ...formItemRequired,
        description7Required: value ? true : false,
      });
    } else if (fieldName === "badgeId8") {
      setFormItemRequired({
        ...formItemRequired,
        description8Required: value ? true : false,
      });
    } else if (fieldName === "badgeId9") {
      setFormItemRequired({
        ...formItemRequired,
        description9Required: value ? true : false,
      });
    } else if (fieldName === "badgeId10") {
      setFormItemRequired({
        ...formItemRequired,
        description10Required: value ? true : false,
      });
    }
  };

  return (
    <div>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner" />
        </div>
      )}
      <Modal
        width="800px"
        title={!props.record ? "Create New Checklist" : "Edit Checklist"}
        open={true}
        onCancel={() => props.closeModal()}
        onOk={() => form.submit()}
        okText={!props.record ? "Create" : "Update"}
        okButtonProps={
          props.viewMode ? { style: { display: "none" } } : undefined
        }
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      >
        <Form
          form={form}
          onFinish={saveCheckList}
          scrollToFirstError
          name="validateOnly"
          disabled={props.viewMode}
          layout="vertical"
          autoComplete="off"
        >
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="name"
                label="Checklist Name"
                rules={[
                  { required: true, message: "Please enter checklist name!" },
                ]}
              >
                <Input placeholder="Enter Checklist Name" />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="iconURL"
                label="Icon"
                rules={[
                  { required: false, message: "Please select header icon!" },
                ]}
              >
                <Upload
                  listType="picture"
                  onRemove="true"
                  name="iconURL"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={uploadImage}
                  beforeUpload={false}
                  onChange={handleIconChange}
                >
                  {iconURL ? (
                    <img
                      src={iconURL}
                      alt="avatar"
                      style={{
                        width: "35px",
                        background: "radial-gradient(#3d5ed1, transparent)",
                        borderRadius: "50%",
                        padding: "5px",
                      }}
                    />
                  ) : (
                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={17}>
              <Form.Item name="completionMessage" label="Completion Message">
                <Input placeholder="Input Completion Message" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="description1"
                label="Checklist Item Description"
                rules={[
                  {
                    required: formItemRequired.description1Required,
                    message: "Please enter item description!",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    handleFormChange("description1", e.target.value)
                  }
                  placeholder="Enter Checklist Name"
                />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <FormItem
                name="badgeId1"
                label="Badge"
                rules={[
                  {
                    required: formItemRequired.badgeId1Required,
                    message: "Please select badge!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("badgeId1", value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {props.badgeList.map((badge) => (
                    <Option key={badge.id} value={badge.id} label={badge.name}>
                      {badge.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="description2"
                label="Checklist Item Description"
                rules={[
                  {
                    required: formItemRequired.description2Required,
                    message: "Please enter item description!",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    handleFormChange("description2", e.target.value)
                  }
                  placeholder="Enter Checklist Name"
                />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <FormItem
                name="badgeId2"
                label="Badge"
                rules={[
                  {
                    required: formItemRequired.badgeId2Required,
                    message: "Please select badge!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("badgeId2", value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {props.badgeList.map((badge) => (
                    <Option key={badge.id} value={badge.id} label={badge.name}>
                      {badge.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="description3"
                label="Checklist Item Description"
                rules={[
                  {
                    required: formItemRequired.description3Required,
                    message: "Please enter item description!",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    handleFormChange("description3", e.target.value)
                  }
                  placeholder="Enter Checklist Name"
                />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <FormItem
                name="badgeId3"
                label="Badge"
                rules={[
                  {
                    required: formItemRequired.badgeId3Required,
                    message: "Please select badge!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("badgeId3", value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {props.badgeList.map((badge) => (
                    <Option key={badge.id} value={badge.id} label={badge.name}>
                      {badge.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="description4"
                label="Checklist Item Description"
                rules={[
                  {
                    required: formItemRequired.description4Required,
                    message: "Please enter item description!",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    handleFormChange("description4", e.target.value)
                  }
                  placeholder="Enter Checklist Name"
                />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <FormItem
                name="badgeId4"
                label="Badge"
                rules={[
                  {
                    required: formItemRequired.badgeId4Required,
                    message: "Please select badge!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("badgeId4", value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {props.badgeList.map((badge) => (
                    <Option key={badge.id} value={badge.id} label={badge.name}>
                      {badge.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="description5"
                label="Checklist Item Description"
                rules={[
                  {
                    required: formItemRequired.description5Required,
                    message: "Please enter item description!",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    handleFormChange("description5", e.target.value)
                  }
                  placeholder="Enter Checklist Name"
                />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <FormItem
                name="badgeId5"
                label="Badge"
                rules={[
                  {
                    required: formItemRequired.badgeId5Required,
                    message: "Please select badge!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("badgeId5", value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {props.badgeList.map((badge) => (
                    <Option key={badge.id} value={badge.id} label={badge.name}>
                      {badge.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="description6"
                label="Checklist Item Description"
                rules={[
                  {
                    required: formItemRequired.description6Required,
                    message: "Please enter item description!",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    handleFormChange("description6", e.target.value)
                  }
                  placeholder="Enter Checklist Name"
                />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <FormItem
                name="badgeId6"
                label="Badge"
                rules={[
                  {
                    required: formItemRequired.badgeId6Required,
                    message: "Please select badge!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("badgeId6", value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {props.badgeList.map((badge) => (
                    <Option key={badge.id} value={badge.id} label={badge.name}>
                      {badge.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="description7"
                label="Checklist Item Description"
                rules={[
                  {
                    required: formItemRequired.description7Required,
                    message: "Please enter item description!",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    handleFormChange("description7", e.target.value)
                  }
                  placeholder="Enter Checklist Name"
                />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <FormItem
                name="badgeId7"
                label="Badge"
                rules={[
                  {
                    required: formItemRequired.badgeId7Required,
                    message: "Please select badge!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("badgeId7", value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {props.badgeList.map((badge) => (
                    <Option key={badge.id} value={badge.id} label={badge.name}>
                      {badge.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="description8"
                label="Checklist Item Description"
                rules={[
                  {
                    required: formItemRequired.description8Required,
                    message: "Please enter item description!",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    handleFormChange("description8", e.target.value)
                  }
                  placeholder="Enter Checklist Name"
                />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <FormItem
                name="badgeId8"
                label="Badge"
                rules={[
                  {
                    required: formItemRequired.badgeId8Required,
                    message: "Please select badge!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("badgeId8", value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {props.badgeList.map((badge) => (
                    <Option key={badge.id} value={badge.id} label={badge.name}>
                      {badge.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="description9"
                label="Checklist Item Description"
                rules={[
                  {
                    required: formItemRequired.description9Required,
                    message: "Please enter item description!",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    handleFormChange("description9", e.target.value)
                  }
                  placeholder="Enter Checklist Name"
                />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <FormItem
                name="badgeId9"
                label="Badge"
                rules={[
                  {
                    required: formItemRequired.badgeId9Required,
                    message: "Please select badge!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("badgeId9", value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {props.badgeList.map((badge) => (
                    <Option key={badge.id} value={badge.id} label={badge.name}>
                      {badge.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8}>
              <FormItem
                name="description10"
                label="Checklist Item Description"
                rules={[
                  {
                    required: formItemRequired.description10Required,
                    message: "Please enter item description!",
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    handleFormChange("description10", e.target.value)
                  }
                  placeholder="Enter Checklist Name"
                />
              </FormItem>
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={8}>
              <FormItem
                name="badgeId10"
                label="Badge"
                rules={[
                  {
                    required: formItemRequired.badgeId10Required,
                    message: "Please select badge!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("badgeId10", value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                >
                  {props.badgeList.map((badge) => (
                    <Option key={badge.id} value={badge.id} label={badge.name}>
                      {badge.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ChecklistModal;
