import React, { useEffect, useState, useRef } from "react";
import { Flex, Breadcrumb, Tag } from "antd";
import grapesjs from "grapesjs";
import plugin from "grapesjs-blocks-basic";
import {
  ClockCircleOutlined,
} from '@ant-design/icons';
import { getApi, postApi } from "../services/api.jsx";
import carouselSample from "../assets/img/carousel-sample.png";
import checklistSample from "../assets/img/checklist-sample.png";
import moment from "moment";
import gridSample from "../assets/img/card-sample.png";
import Swal from "sweetalert2";
const LandingEditor = () => {
  const [editor, setEditor] = useState(null);
  const [categoryBlocks, setCategoryBlocks] = useState([]);
  const [checkListBlocks, setCheckListBlocks] = useState([]);
  const [infoMessageListBlocks, setInfoMessagesListBlocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [order, setOrder] = useState("");
  const [id, setId] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [lastUpdatedDate, setLastUpdatedDate] = useState([]);
  const [lastUpdatedBy, setLastUpdatedBy] = useState([]);


  useEffect(() => {
    setLoading(true);
    Promise.all([getCategoryList(), getCheckList(), getInfoMessageList()])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error while fetching categories and checklists:", error);
        setLoading(false);
      });
  }, []);

  const getCategoryList = () => {
    getApi("/category/list")
      .then((category) => {
        const categoryCards = category.map((item, index) => {
          const customCategoryCardContent = `<div class="category-card" data-gjs-type="category" custom-id="${item.id
            }" >
              <div class="badge-headline-area">
              <style>
              .category-card {
                background:#222;
                margin: 10px;
                border-radius: 10px 10px 0 0;
            }
              .badge-headline-area {
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  flex-wrap: nowrap;
                  padding: 0 5px 0 18px;
                  border-bottom: 1px solid #e8e9eb7a;
                  text-transform: uppercase;
              }

              .badge-headline {
                font-family: system-ui;
                font-weight: 700;
                color: #f5f4f2;
                font-size: 14px;
                margin-left:5px;
            }
          
              
            }
          
          </style>
               <Image src=${item.iconURL
            } width="25px" data-gjs-type="category" /> 
               <h3 class="badge-headline" data-gjs-type="category">${item.name} 
               </h3>
              </div>
              ${item.placementType === "Carousel"
              ? `<Image src=${carouselSample} width="100%" data-gjs-type="category"/>`
              : item.placementType === "Grid"
                ? `<Image src=${gridSample} width="100%" data-gjs-type="category"/>`
                : `<Image src=${carouselSample} width="100%" data-gjs-type="category"/>`
            }
           
            </div>`;

          return {
            id: `${item.id}`,
            label: `<span>${item.name}</span>`,
            category: "Categories",
            content: {
              id: `${item.id}`,
              type: "category",
              content: customCategoryCardContent,
              attributes: { class: "category-card" },
              draggable: true,
              droppable: true,
              copyable: true,
              removable: true,
            },
            attributes: { class: "wo" },
            type: "category",
          };
        });
        setCategoryBlocks(categoryCards);
      })
      .catch((error) => {
        console.error("Error while fetching category list:", error);
      });
  };

  const getCheckList = () => {
    getApi("/checklist/list").then((checkList) => {
      const checkListCards = checkList.map((item, index) => {
        const customCheckListCardContent = `
          <div class="checklist-card" data-gjs-type="checklist" data-id="${item.id}">
            <Image src=${checklistSample} width="100%" data-gjs-type="checklist"/>
          </div>`;
        return {
          id: `${item.id}`,
          label: `<span>${item.name}</span>`,
          category: "Checklists",
          content: {
            id: `${item.id}`,
            type: "checklist",
            content: customCheckListCardContent,
            attributes: { class: "checlist-card" },
            draggable: true,
            droppable: true,
            copyable: true,
            removable: true,
          },
          attributes: { class: "wo" },
          type: "checklist",
        };
      });
      setCheckListBlocks(checkListCards);
    });
  };



  const getInfoMessageList = () => {
    getApi("/infoMessage/list").then((infoMessages) => {
      const infoMessageCards = infoMessages.map((item, index) => {
        const customCheckListCardContent = `
          <div class="info-card" style="background:${item.backgroundColor}; display: flex;
          align-items: center; margin:10px; border-radius:5px;
          padding: 10px;" data-gjs-type="info" data-id="${item.id}">
              <Image
              src=${item.iconURL}
              alt="Icon"
              width={35}
              height={35}
              style={{
               
                width: "50px;",
                padding: "5px",
              }}
            />
                <span style="color:#fff; margin-left:10px; font-family:"inherit">${item.description}</span>

          </div>`;
        return {
          id: `${item.id}`,
          label: `<span style="max-width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          ${item.description}
        </span>`,
          category: "Info Messages",
          content: {
            id: `${item.id}`,
            type: "info",
            content: customCheckListCardContent,
            attributes: { class: "checlist-card" },
            draggable: true,
            droppable: true,
            copyable: true,
            removable: true,
          },
          attributes: { class: "wo" },
          type: "checklist",
        };
      });
      setInfoMessagesListBlocks(infoMessageCards);
    });
  };


  useEffect(() => {
    console.log("templateData:", blocks);
  }, [blocks]);

  const saveLandingTemplate = (blocks) => {
    if (blocks.length === 0) {
      Swal.fire({
        title: 'Warning!',
        text: 'Please make changes before saving.',
        icon: 'warning',
      });
      return;
    }
    const landingTemplatesData = blocks.map(block => ({
      ...block,
      type: block.type.toUpperCase(),
      createdBy: localStorage.getItem("userEmail"),
      lastUpdatedBy: localStorage.getItem("userEmail"),
    }));
    postApi("/template/list", landingTemplatesData)
      .then((data) => {
        console.log("Success:", data);
        const formattedDate = moment(data[0].lastUpdatedDate).format('YYYY-MM-DD HH:mm:ss');
        setLastUpdatedDate(formattedDate)
        Swal.fire({
          title: "success!",
          text: "Template created successfully",
          icon: "success",
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error saving templates:", error);
        Swal.fire({
          title: "Error!",
          text: "Error saving templates",
          error,
          icon: "error",
        });
        setLoading(false);
      });
  };

  const getTemplate = () => {
    setLoading(true);
    getApi("/template/list")
      .then((templates) => {
        console.log("template:", templates);
        let latestDate = templates.reduce((latest, item) => {
          const currentItemDate = moment(item.lastUpdatedDate);
          const lastUpdatedBy = item.lastUpdatedBy
          setLastUpdatedBy(lastUpdatedBy)
          return currentItemDate.isAfter(latest) ? currentItemDate : latest;
        }, moment(0));
        if (latestDate.isValid()) {
          setLastUpdatedDate(latestDate.format('YYYY-MM-DD HH:mm:ss'));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error while fetching template:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTemplate();
  }, []);


  useEffect(() => {
    let updatedBlocks = [];
    const osm = "open-sm";
    const otm = "open-tm";
    const ful = "fullscreen";
    const prv = "preview";
    const svd = "dashboard-save";
    const expt = "export-template";

    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const projectID = "2fb86766-ebab-4df8-b90e-4cd439c0b536";
    const projectEndpointGet = baseURL + `/grapesProject/${projectID}`;
    const projectEndpointPost = baseURL + `/grapesProject`;

    const editorInstance = grapesjs.init({
      container: "#gjs2",
      fromElement: true,
      allowScripts: 1,
      blockManager: {
        appendTo: "#blocks",
        blocks: [],
      },
      style: `
      body {
        background-color: #101010; 
      }
    `,
      storageManager: {
        type: 'remote',
        contentTypeJson: true,
        fromElement: false,
        autosave: true,
        autoload: true,
        stepsBeforeSave: 1,
        storeComponents: true,
        storeStyles: true,
        storeHtml: true,
        storeCss: true,

        options: {
          remote: {
            urlLoad: projectEndpointGet,
            urlStore: projectEndpointPost,
            credentials: 'omit',
            onStore: data => ({ id: projectID, data }),
            onLoad: result => result.data,
            headers: {
              'Authorization': localStorage.getItem('token'),
            },
          }
        }
      },
      assetManager: {
        storageType: "",
        storeOnChange: true,
        storeAfterUpload: true,
        upload: "https://localhost/assets/upload",
        assets: [],
        uploadFile: function (e) {
          var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          var formData = new FormData();
          formData.append("file", files[0]);
          postApi("/file", formData, "multipart/form-data")
            .then(() => {
              console.log("data", files[0]);
              const fileName = files[0].name;
              const fileUrl = process.env.REACT_APP_S3_BASE_URL + fileName;
              setImageFileUrl(fileUrl);
              console.log("fileUrl:", fileUrl);
              editorInstance.AssetManager.add([
                { type: "image", src: fileUrl },
              ]);
            })
            .catch((error) => {
              console.error("error:", error);
            });
        },
      },
      plugins: [plugin],
      avoidInlineStyle: true,
      pluginsOpts: {
        plugin: {
          category: "Layout",
        },
      },
      panels: {
        defaults: [
          {
            id: "options",
            buttons: [
              {
                id: "undo",
                className: "fa fa-undo text-white",
                context: "Undo",
                command: "core:undo",
                attributes: { title: "Undo" },
              },
              {
                id: "redo",
                className: "fa fa-repeat text-white me-5",
                context: "Redo",
                command: "core:redo",
                attributes: { title: "Rdo" },
              },
              {
                id: prv,
                className: "fa fa-eye text-white",
                command: prv,
                context: prv,
                attributes: { title: "Preview" },
              },
              {
                id: ful,
                className: "fa fa-arrows-alt bg-warning text-white",
                command: ful,
                context: ful,
                attributes: { title: "Fullscreen" },
              },
              {
                id: expt,
                className: "fa fa-code",
                command: expt,
                attributes: { title: "View code" },
              },
              {
                id: svd,
                className: "fa fa-send bg-success text-white",
                command: "saveBlocksToSave",
                attributes: { title: "Save Template" },
              },
            ],
          },
          {
            id: "views",
            buttons: [
              {
                id: osm,
                className: "fa fa-paint-brush",
                command: osm,
                active: true,
                togglable: 0,
                attributes: { title: "Open Style Manager" },
              },
              {
                id: otm,
                className: "fa fa-cog",
                command: otm,
                togglable: 0,
                attributes: { title: "Settings" },
              },
            ],
          },
          {
            id: "left",
            el: "#left",
            resizable: {
              tc: 0,
              cr: 1,
              bc: 0,
              keyWidth: "flex-basis",
            },
          },
          {
            id: "right",
            el: "#right",
            resizable: {
              tc: 0,
              cr: 0,
              cl: 1,
              bc: 0,
              keyWidth: "flex-basis",
            },
          },
        ],
      },
    });

    setEditor(editorInstance);

    editorInstance.Commands.add("saveBlocksToSave", {
      run: function (editorInstance, sender) {
        saveLandingTemplate(updatedBlocks);
      },
    });

    function updateComponentOrder(editor) {
      const allComponents = editor.DomComponents.getComponents();
      allComponents.each((component, index) => {
        component.set("order", index + 1);
      });
    }
    function convertStyleKeysToCamelCase(styles) {
      const convertedStyles = {};
      Object.keys(styles).forEach(key => {
        const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        convertedStyles[camelCaseKey] = styles[key];
      });
      return convertedStyles;
    }
    editorInstance.on("component:add", (component, imageFileUrl) => {
      if (!component.parent() || component.parent().is("wrapper")) {
        updateComponentOrder(editorInstance);
        const typeBlock =
          component.getAttributes()["data-gjs-type"] || component.get("type");
        setType(typeBlock);
        editorInstance.DomComponents.getComponents().each((comp, index) => {
          if (comp.getId() === component.getId()) {
            const allComponents = editorInstance.DomComponents.getComponents();
            let textContent = null;
            let url = null;
            let title = null;
            let href = null;

            if (comp.get("type") === "text") {
              textContent = comp.toHTML();
            }
            if (comp.get("type") === "video") {
              url = comp.get("src");
            } else if (comp.get("type") === "image") {
              url = imageFileUrl;
            } else if (comp.get('type') === 'link') {
              url = comp.getAttributes().href;
              title = comp.getAttributes().title;
            }
            const styles = comp.getStyle();
            const convertedStyles = convertStyleKeysToCamelCase(styles);
            updatedBlocks = allComponents.map((comp, index) => {
              return {
                id: comp.id,
                type: comp.getAttributes()["data-gjs-type"] || comp.get("type"),
                order: index,
                title: textContent || title,
                description: href,
                url: url,
                styles: convertedStyles
              };
            });
            setBlocks(updatedBlocks);

            const lastAdded = updatedBlocks[updatedBlocks.length - 1];
            if (lastAdded) {
              setId(lastAdded.id);
              setType(lastAdded.type);
              setOrder(lastAdded.order);
            }
          }
        });
      }
    });

    editorInstance.on("component:update", (component) => {
      const allComponents = editorInstance.DomComponents.getComponents().models;
      updatedBlocks = allComponents.map((comp, index) => {
        let textContent = null;
        let url = null;
        let title = null;
        let href = null;

        if (comp.get("type") === "text") {
          textContent = comp.toHTML();
        }
        if (comp.get("type") === "video") {
          url = comp.get("src");
        } else if (comp.get("type") === "image") {
          url = comp.get("attributes").src;
        } else if (comp.get('type') === 'link') {
          url = comp.getAttributes().href;
          title = comp.getAttributes().title;
          href = comp.getAttributes().href;
        }
        const styles = comp.getStyle();
        const convertedStyles = convertStyleKeysToCamelCase(styles);
        return {
          id: comp.id,
          type: comp.getAttributes()["data-gjs-type"] || comp.get("type"),
          order: index,
          title: textContent || title,
          description: href,
          url: url,
          styles: convertedStyles
        };
      });
      setBlocks(updatedBlocks);
    });

    editorInstance.on("component:drag:end", () => {
      editorInstance.DomComponents.getComponents().each((component, index) => {
        const newOrder = index;
        component.set("order", newOrder);
      });
    });

    editorInstance.on('component:remove', (component) => {
      const removedComponentId = component.getId();
      const newBlocks = blocks.filter(block => block.id !== removedComponentId)
        .map((block, index) => ({ ...block, order: index }));
      setBlocks(newBlocks);
    });

    const { $ } = editorInstance;
    const $left = $("#left");
    const $editorContainer = $(".editor-clm");
    const $right = $("#right");

    editorInstance.on("load", () => {
      $left.append(editorInstance.Blocks.render());
      $right.append([$(".gjs-pn-views"), $(".gjs-pn-views-container")]);
      $editorContainer.prepend($(".gjs-pn-panels"));
    });
    return () => {
      editorInstance.destroy();
    };
  }, []);

  useEffect(() => {
    if (editor && categoryBlocks.length > 0 && checkListBlocks.length > 0) {
      editor.BlockManager.blocks.add(categoryBlocks);
      editor.BlockManager.blocks.add(checkListBlocks);
      editor.BlockManager.blocks.add(infoMessageListBlocks);
    }
  }, [editor, categoryBlocks, checkListBlocks, infoMessageListBlocks]);

  return (
    <div>
      <style>
        {`
            .ant-layout-content {
                background: rgb(68, 68, 68)!important;
            }
            `}
      </style>
      <Flex
        gap="middle"
        align="center"
        justify="space-between"
        className="page-header"
      >
        <h2>Fan Landing Template Editor</h2>

        <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: <a href="">Landing Page Editor</a>,
            },
          ]}
        />
        <Flex>   <Tag icon={<ClockCircleOutlined />} color="cyan">
          Last Updated Date: {lastUpdatedDate}
        </Tag>  <Tag icon={<ClockCircleOutlined />} color="purple">
            Last Updated By: {lastUpdatedBy}
          </Tag>  </Flex>
      </Flex>
      <Flex>
        <div id="left" className="column" style={{ flexBasis: "200px" }}></div>
        <div className="column editor-clm gjs-one-bg">
          <div id="blocks"></div>
          <div id="gjs2" style={{ overflow: "hidden" }}></div>
        </div>
        <div id="right" className="column" style={{ flexBasis: "250px" }}>
          Style Settings
          <br />
        </div>
      </Flex>
    </div>
  );
};

export default LandingEditor;
