import React from "react";

import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { encode as base64_encode } from "base-64";
import { loginApi } from "../services/api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const FormItem = Form.Item;

const Login = () => {
  const [loginForm] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = (values) => {
    const auth = "Basic " + base64_encode(values.email + ":" + values.password);
    loginApi(auth)
      .then((tokenData) => {
        localStorage.setItem(
          "token",
          tokenData.data.tokenType + " " + tokenData.data.accessToken
        );
        localStorage.setItem("userEmail", values.email);
        localStorage.setItem(
          "userGroups",
          JSON.stringify(tokenData.data.groups)
        );
      })
      .then(() => {
        navigate("/dashboard");
      })
      .catch((ex) => {
        Swal.fire({
          title: "Error!",
          text: "Login failed! Please check your email and password.",
          ex,
          icon: "error",
        });
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <Form
      form={loginForm}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <FormItem
        name="email"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "Please enter a vaild email address!",
          },
          {
            pattern:
              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            message: "Please enter a valid email address!",
          },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Email" />
      </FormItem>
      <FormItem
        name="password"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "Please enter a valid password!",
          },
          {
            pattern:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.,#@$!%*?&])[A-Za-z\d.,#@$!%*?&]{8,}$/i,
            message:
              "Password should contain at least 8 character with at least one of these symbols [.,#@$!%*?&], one uppercase, one lower case letter and a number",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
        />
      </FormItem>
      <FormItem>
        <Button
          style={{ backgroundColor: "#08184F", color: "#fff" }}
          htmlType="submit"
          block
          shape="round"
        >
          Log in
        </Button>
      </FormItem>
    </Form>
  );
};

export default Login;
