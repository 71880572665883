import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  Flex,
  Row,
  Col,
  Image,
  Form,
  Button,
  ColorPicker,
  Tabs,
  Badge,
  Upload,
  Input,
  Select,
  Space,
  Switch,
  DatePicker,
} from "antd";
import {
  StarOutlined,
  DatabaseOutlined,
  UploadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { getParamsApi } from "../services/api.jsx";
import "react-quill/dist/quill.snow.css";
import TabPane from "antd/es/tabs/TabPane.js";
import dayjs from "dayjs";
import moment from "moment";
import ReactQuill from "react-quill";
import { getApi, postApi } from "../services/api.jsx";

const BadgePreview = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [buttonColor, setButtonColor] = useState("#F35454");
  const [buttonTextColor, setButtonTextColor] = useState("#ffffff");
  const [fontColor, setFontColor] = useState("#ffffff");
  const [backgroundColor, setBackgroundColor] = useState("#0E0F11");
  const [loading, setLoading] = useState(false);
  const [badgeStatus, setBadgeStatus] = useState("");
  const [badgeData, setBadgeData] = useState("");
  const [dataSourceList, setDataSourceList] = useState([]);
  const [checkedReward, setCheckedReward] = useState(false);

  const { id } = useParams();
  const getBadgeData = (badgeId) => {
    setLoading(true);
    const params = { id: badgeId };
    getParamsApi(`/badge/${badgeId}`, params)
      .then((response) => {
        console.log("Badge data:", response);
        setBadgeData(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching badge data:", error);
        setLoading(false);
      });
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "code"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  const TextEditor = ({ value, onChange, placeholder }) => {
    return (
      <>
        <ReactQuill
          readOnly
          theme="snow"
          value={value || ""}
          modules={modules}
          formats={formats}
          onChange={onChange}
          placeholder={placeholder}
        />
      </>
    );
  };

  const getDataSourceList = () => {
    getApi("/badge/datasource")
      .then((dataSources) => {
        setDataSourceList(dataSources);
      })
      .catch((ex) => {
        console.error("Error fetching datasource list:", ex);
      });
  };

  useEffect(() => {
    getDataSourceList();
  }, []);

  useEffect(() => {
    getBadgeData(id);
  }, [id]);

  useEffect(() => {
    const hasReward = badgeData.rewardDescription !== null;
    setCheckedReward(hasReward);
    const formData = {
      ...badgeData,
      publishDate: badgeData.publishDate
        ? moment(badgeData.publishDate)
        : undefined,
      expireDate: badgeData.expireDate
        ? moment(badgeData.expireDate)
        : undefined,
      hasReward: hasReward,
    };
    form.setFieldsValue(formData);
  }, [form, badgeData]);
  return (
    <div>
      <Flex
        gap="middle"
        align="center"
        justify="space-between"
        className="page-header"
      >
        <h2>
          <EyeOutlined /> {badgeData.name}
        </h2>
        <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: <a href="">Badge Editor</a>,
            },
          ]}
        />
      </Flex>

      <Row style={{ padding: "20px 10px" }}>
        <Col span={16}>
          <Form
            disabled
            form={form}
            scrollToFirstError
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            initialValues={badgeData}
          >
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={
                  <span>
                    <StarOutlined className="me-5" />
                    Badge Info
                  </span>
                }
                key="1"
              >
                <Flex>
                  <Flex style={{ width: "45%", marginRight: "3%" }} vertical>
                    <Form.Item
                      name="imageURL"
                      label="Badge Image"
                      rules={[
                        { required: true, message: "Please upload image!" },
                      ]}
                    >
                      <Upload
                        onRemove="true"
                        name="imageURL"
                        listType="picture-circle"
                        className="avatar-uploader"
                        showUploadList={false}
                      >
                        {badgeData.imageURL ? (
                          <img
                            src={badgeData.imageURL}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name="id"
                      value="sdfsdfsd"
                      rules={[
                        {
                          required: true,
                          message: "Please input badge number!",
                        },
                      ]}
                    >
                      <Input defaultValue={badgeData.id} />
                    </Form.Item>
                    <Form.Item
                      name="name"
                      label="Badge Name"
                      rules={[
                        { required: true, message: "Please input badge name!" },
                      ]}
                    >
                      <Input placeholder="input badge name" />
                    </Form.Item>
                    <Form.Item
                      name="headline"
                      label="Headline"
                      rules={[
                        { required: true, message: "Please enter headline!" },
                      ]}
                    >
                      <Input
                        placeholder="input headline"
                        value={badgeData.headline}
                      />
                    </Form.Item>
                    <Form.Item
                      name="type"
                      label="Category"
                      rules={[
                        { required: true, message: "Please select category!" },
                      ]}
                    >
                      <Select name="type" placeholder="Select badge category">
                        <Option></Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="description"
                      label="Badge Description"
                      maxLength="100"
                      rules={[
                        {
                          required: true,
                          message: "Please input badge description!",
                        },
                      ]}
                    >
                      <TextEditor />
                    </Form.Item>
                    <Form.Item
                      name="hoverDescription"
                      label="Hover Badge Description"
                    >
                      <TextEditor />
                    </Form.Item>
                  </Flex>

                  <Flex vertical>
                    <Form.Item name="videoURL" label="Video/Image URL">
                      <Input placeholder="input embed video URL" />
                    </Form.Item>

                    <Form.Item name="altText" label="Alt Text">
                      <Input placeholder="input alt text " />
                    </Form.Item>
                    <Flex>
                      <Form.Item
                        name="backgroundColor"
                        className="ms-5"
                        label="Backround Color"
                        getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }}
                        rules={[
                          {
                            required: false,
                            message: "select background color!",
                          },
                        ]}
                      >
                        <ColorPicker
                          showText
                          defaultValue="#1E222B"
                          value={backgroundColor}
                        />
                      </Form.Item>
                      <Form.Item
                        className="ms-5"
                        name="backgroundImageURL"
                        label="Background Image"
                        rules={[
                          { required: false, message: "Please select font!" },
                        ]}
                      >
                        <Upload
                          showRemoveIcon={true}
                          name="backgroundImageURL"
                          showUploadList={true}
                        >
                          <Button icon={<UploadOutlined />}>
                            Upload Image
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Flex>

                    <Flex>
                      <Form.Item
                        name="font"
                        style={{ width: "200px" }}
                        label="Font"
                        rules={[
                          { required: false, message: "Please select font!" },
                        ]}
                      >
                        <Select placeholder="select font">
                          <Option value="Poppins">Poppins</Option>
                          <Option value="Roboto">Roboto</Option>
                          <Option value="Custom">Custom</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="fontColor"
                        className="ms-5"
                        label="Font Color"
                        getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }}
                        rules={[
                          {
                            required: false,
                            message: " select font color!",
                          },
                        ]}
                      >
                        <ColorPicker
                          showText
                          defaultValue="white"
                          value={fontColor}
                        />
                      </Form.Item>
                    </Flex>

                    <Form.Item
                      name="buttonName"
                      label="Button Name"
                      rules={[
                        {
                          required: false,
                          message: "Please input Button Name!",
                        },
                      ]}
                    >
                      <Input placeholder="input button name" />
                    </Form.Item>
                    <Form.Item
                      name="buttonLinkURL"
                      label="Button URL"
                      rules={[
                        {
                          required: false,
                          message: "Please input Button URL!",
                        },
                      ]}
                    >
                      <Input addonBefore="https://" />
                    </Form.Item>
                    <Flex>
                      <Form.Item
                        name="buttonColor"
                        className="ms-5"
                        label="Button Color"
                        getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }}
                        rules={[
                          {
                            required: false,
                            message: " select button color!",
                          },
                        ]}
                      >
                        <ColorPicker
                          showText
                          defaultValue="white"
                          value={buttonColor}
                        />
                      </Form.Item>

                      <Form.Item
                        name="buttonTextColor"
                        className="ms-5"
                        label="Button Text Color"
                        getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }}
                        rules={[
                          {
                            required: false,
                            message: "Select button text color!",
                          },
                        ]}
                      >
                        <ColorPicker
                          showText
                          defaultValue="white"
                          value={buttonColor}
                        />
                      </Form.Item>
                    </Flex>
                    <Form.Item
                      name="hasReward"
                      label="Is there a reward for this badge?"
                    >
                      <Switch checkedChildren="Yes" unCheckedChildren="No" />
                    </Form.Item>
                    {checkedReward && (
                      <Form.Item
                        name="rewardDescription"
                        label="Reward Description"
                        rules={[
                          {
                            required: true,
                            message: "Please input reward description!",
                          },
                        ]}
                      >
                        <Input placeholder="Badge Reward Description" />
                      </Form.Item>
                    )}
                    <Flex>
                      <Form.Item
                        className="me-5"
                        name="publishDate"
                        label="Publish Date"
                        rules={[
                          {
                            required: false,
                            message: "Please select publish date!",
                          },
                        ]}
                      >
                        <DatePicker showTime maxDate={dayjs('2028-01-01')} />
                      </Form.Item>
                      <Form.Item
                        name="expireDate"
                        label="Expire Date"
                        rules={[
                          {
                            required: false,
                            message: "Please select expiry date!",
                          },
                        ]}
                      >
                        <DatePicker maxDate={dayjs('2099-01-01')} />
                      </Form.Item>
                    </Flex>
                  </Flex>
                </Flex>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <DatabaseOutlined className="me-5" />
                    Data
                    <Badge
                      status={badgeStatus}
                      text={
                        badgeStatus == "error" ? (
                          <span style={{ fontStyle: "italic", color: "red" }}>
                            Please enter data into the fields before saving!
                          </span>
                        ) : (
                          ""
                        )
                      }
                      className="ms-5"
                    ></Badge>
                  </span>
                }
                key="2"
              >
                <Flex style={{ width: "50%" }} vertical>
                  <Form.Item
                    name="dataSource"
                    label="Datasource"
                    rules={[
                      { required: true, message: "Please select datasource!" },
                    ]}
                  >
                    <Select placeholder="Select datasource">
                      {dataSourceList.map((dataSource) => (
                        <Option
                          key={dataSource}
                          value={dataSource}
                          label={dataSource}
                        >
                          {dataSource}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="selectionCriteria"
                    label="Selection Criteria"
                    rules={[
                      { required: true, message: "Please selection criteria!" },
                    ]}
                  >
                    <Input placeholder="Select criteria" />
                  </Form.Item>
                  <Form.Item
                    name="suppressionCriteria"
                    label="Suppression Criteria"
                    rules={[
                      {
                        required: true,
                        message: "Please input suppression Criteria!",
                      },
                    ]}
                  >
                    <Input placeholder="Suppression Criteria" />
                  </Form.Item>
                  <Form.Item
                    name="badgeStatusCalculation"
                    label="Badge Status Calculation"
                    rules={[
                      {
                        required: true,
                        message: "Please input Badge Status Calculation!",
                      },
                    ]}
                  >
                    <Input placeholder="Badge Status Calculation" />
                  </Form.Item>
                  <Form.Item
                    name="badgeStatusCompletionValue"
                    label="Badge Status Completion Value"
                    rules={[
                      {
                        required: true,
                        message: "Please input badge status completion value!",
                      },
                    ]}
                  >
                    <Input placeholder="Badge Status Calculation" />
                  </Form.Item>
                </Flex>
              </TabPane>
            </Tabs>
          </Form>
        </Col>
        <Col span={8}>
          <Flex
            style={{
              backgroundImage: badgeData.backgroundImageURL
                ? `url(${badgeData.backgroundImageURL})`
                : "none",
              backgroundSize: "cover",
              backgroundColor: backgroundColor,
              color: fontColor,
            }}
            className="w-100 h-100 rounded-10"
            vertical
            justify="space-between"
          >
            <>
              <div className="text-center p-15">
                <div className="night">
                  <div className="star"></div>
                  <div className="star"></div>
                  <div className="star"></div>
                  <div className="star"></div>
                  <div className="star"></div>
                </div>
                <Image
                  src={badgeData.imageURL}
                  alt="Badge"
                  style={{
                    width: "150px",
                  }}
                />
                <h3>{badgeData.headline}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: badgeData.description,
                  }}
                />
                {badgeData.buttonName && (
                  <Button
                    href={badgeData.buttonLinkURL}
                    style={{
                      backgroundColor: badgeData.buttonColor,
                      color: badgeData.buttonTextColor,
                      borderColor: badgeData.buttonTextColor
                    }}
                  >
                    {badgeData.buttonName}
                  </Button>
                )}
              </div>
              <div>
                <iframe
                  className="prev-iframe"
                  width="100%"
                  height="315"
                  src={badgeData.videoURL}
                  title={badgeData.name}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </>
          </Flex>
        </Col>
      </Row>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};

export default BadgePreview;
