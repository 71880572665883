import React, { useRef, useState, useEffect } from "react";
import {
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
  UploadOutlined,
  RadiusUprightOutlined,
  PlusCircleOutlined,
  StarOutlined,
  ArrowUpOutlined,
  ArrowRightOutlined,
  ArrowDownOutlined,
  BorderOuterOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Flex,
  Space,
  Form,
  Input,
  Row,
  Col,
  message,
  Button,
  Upload,
  Table,
  Popconfirm,
  Image,
  ColorPicker,
  Select,
  Switch,
  Radio,
  Modal,
} from "antd";
import { getApi, postApi, deleteApi, getParamsApi } from "../services/api.jsx";
import Highlighter from "react-highlight-words";
import Swal from "sweetalert2";
import noImage from "../assets/img/noimage.png";
import SortableTable from "../components/sortableTable.jsx";

const s3BaseURL = process.env.REACT_APP_S3_BASE_URL;

const Dashboard = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [formUpdate] = Form.useForm();
  const [formCreate] = Form.useForm();
  const [fontColor, setFontColor] = useState("");
  const [lineColor, setLineColor] = useState("");
  const [tagFontColor, setTagFontColor] = useState("");
  const [tagBackgroundColor, setTagBackgroundColor] = useState("");
  const [infoFontColor, setInfoFontColor] = useState("");
  const [infoBackgroundColor, setInfoBackgroundColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState("");
  const [categoryData, setCategoryData] = useState("");
  const [categoryBadges, setCategoryBadges] = useState("");
  const [iconURL, setIconURL] = useState("");
  const [backgroundImageURL, setBackgroundImageURL] = useState("");
  const [formatHex, setFormatHex] = useState("hex");
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isBadgeModalVisible, setIsBadgeModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [layoutValue, setLayoutValue] = useState("FullWidth");
  const [buttonColor, setButtonColor] = useState("#F35454");
  const [buttonTextColor, setButtonTextColor] = useState("#ffffff");
  const [paddingValues, setPaddingValues] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleRadioChange = (value) => {
    setLayoutValue(value);
  };
  const { Option } = Select;
  const { TextArea } = Input;

  useEffect(() => {
    getCategoryList();
    getCategoryBadges();
  }, []);

  useEffect(() => {
    formUpdate.setFieldsValue(categoryData);
    setIconURL(categoryData.iconURL);
    setBackgroundImageURL(categoryData.backgroundImageURL);
  }, [formUpdate, categoryData]);

  const handleCloseCreateModal = () => {
    setIsCreateModalVisible(false);
    setIconURL();
    setBackgroundImageURL();
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalVisible(false);
    setIconURL();
    setBackgroundImageURL();
  };

  const handlePaddingInputChange = (value, direction) => {
    setPaddingValues((prevValues) => ({
      ...prevValues,
      [direction]: value,
    }));
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space></Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleIconChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        let iconUrl = s3BaseURL + info.file.originFileObj.name;
        setIconURL(iconUrl);
      });
    }
  };

  const handleBackgroundImageChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        let backgroundImageURL = s3BaseURL + info.file.originFileObj.name;
        setBackgroundImageURL(backgroundImageURL);
      });
    }
  };
  const columns = [
    {
      title: "Header Icon",
      dataIndex: "iconURL",
      key: "iconURL",
      render: (value, record) => (
        <>
          {record.iconURL ? (
            <Image
              src={record.iconURL}
              alt="Icon"
              width={35}
              height={35}
              style={{
                background: "radial-gradient(#3d5ed1, transparent)",
                borderRadius: "50%",
                width: "50px;",
                padding: "5px",
              }}
            />
          ) : (
            <Image
              src={noImage}
              alt="Default Image"
              width={35}
              style={{ opacity: ".5" }}
            />
          )}
        </>
      ),
    },
    {
      title: "Background Image",
      dataIndex: "backgroundImageURL",
      key: "backgroundImageURL",
      render: (value, record) => (
        <>
          {record.backgroundImageURL ? (
            <Image src={record.backgroundImageURL} alt="Icon" width={50} />
          ) : (
            <Image
              src={noImage}
              alt="Default Image"
              width={35}
              style={{ opacity: ".5" }}
            />
          )}
        </>
      ),
    },
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      filterMode: "tree",
      filterSearch: true,
      width: "30%",

      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.createdBy)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.placementType)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Placement Type",
      dataIndex: "placementType",
      key: "placementType",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Button
              type="dashed"
              onClick={() => editCategoryModalOpen(record.id)}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              type="dashed"
              onClick={() => badgeModalOpen(record.id)}
              icon={<StarOutlined />}
            >
              Badges
            </Button>
            <Popconfirm
              title="Are you sure to delete this badge?"
              onConfirm={() => deleteCategory(record.id)}
              okText="Yes"
              cancelText="No"
              zIndex={"9999"}
            >
              <Button type="dashed" danger icon={<DeleteOutlined />}></Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const getCategoryData = (categoryID) => {
    setLoading(true);
    const params = { id: categoryID };
    getParamsApi(`/category/${categoryID}`, params)
      .then((response) => {
        console.log("Category data:", response);
        setCategoryData(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
        setLoading(false);
      });
  };

  const getCategoryBadges = (categoryId) => {
    setLoading(true);
    const params = { categoryId: categoryId };
    getParamsApi(`/badge/category/${categoryId}`, params)
      .then((response) => {
        console.log("Badges:", response);
        setCategoryBadges(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching badgess:", error);
        setLoading(false);
      });
  };

  const deleteCategory = (id) => {
    setLoading(true);
    const params = { id: id };
    deleteApi(`/category/${id}`, params)
      .then(() => {
        getCategoryList();
        console.log("category deleted successfully");
        Swal.fire({
          title: "Success!",
          text: "Category deleted successfully",
          icon: "success",
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
        let errorMessage = "An error occurred while deleting the category";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
        });
        setLoading(false);
      });
  };
  const handleColorChange = (color, setter) => {
    const hexValue = typeof color === "string" ? color : color?.toHexString();
    setter(hexValue);
  };
  const createCategory = (values) => {
    setLoading(true);
    const createCategoryData = {
      ...values,
      createdBy: localStorage.getItem("userEmail"),
      lastUpdatedBy: localStorage.getItem("userEmail"),
      iconURL: iconURL,
      backgroundImageURL: backgroundImageURL,
    };
    if ("iconUrl" || "backgroundImageURL" in values) {
      delete values.iconURL;
      delete values.backgroundImageURL;
    }
    postApi("/category", createCategoryData)
      .then((data) => {
        console.log(data);
        Swal.fire({
          title: "Success!",
          text: "Badge Category Created!",
          icon: "success",
        });
        return getCategoryList();
      })
      .then(() => {
        setLoading(false);
        handleCloseCreateModal();
      })
      .catch((error) => {
        console.error("Error creating badge:", error);
        Swal.fire({
          title: "Error!",
          text: "Error creating badge",
          error,
          icon: "error",
        });
        setLoading(false);
        handleCloseCreateModal();
      });
  };

  const updateCategory = (values) => {
    setLoading(true);
    const updatedCategoryData = {
      ...categoryData,
      ...values,
      lastUpdatedBy: localStorage.getItem("userEmail"),
      iconURL: iconURL,
      backgroundImageURL: backgroundImageURL,
    };
    if ("iconUrl" || "backgroundImageURL" in values) {
      delete values.iconURL;
      delete values.backgroundImageURL;
    }
    postApi("/category", updatedCategoryData)
      .then((data) => {
        console.log(data);
        Swal.fire({
          title: "Success!",
          text: "Badge Category Updated!",
          icon: "success",
        });
        return getCategoryList();
      })
      .then(() => {
        setLoading(false);
        handleCloseUpdateModal();
        getCategoryList();
      })
      .catch((error) => {
        console.error("Error updating badge:", error);
        Swal.fire({
          title: "Error!",
          text: "Error updating badge",
          error,
          icon: "error",
        });
        setLoading(false);
        handleCloseUpdateModal();
      });
  };

  const editCategoryModalOpen = (record) => {
    getCategoryData(record);
    setIsUpdateModalVisible(true);
  };

  const badgeModalOpen = (record) => {
    getCategoryBadges(record);
    setIsBadgeModalVisible(true);
  };

  const createCategoryModalOpen = (record) => {
    formCreate.resetFields();
    setIsCreateModalVisible(true);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const removeBackgroundImage = () => {
    setBackgroundImageURL("");
  };

  const uploadImage = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      postApi("/file", formData, "multipart/form-data").then((data) => {
        console.log(data);
        onSuccess(data, file);
      });
      Swal.fire({
        title: "Success!",
        text: `${file.name} uploaded successfully`,
        icon: "success",
      });
    } catch (error) {
      onError(error);
      Swal.fire({
        title: "Error!",
        text: `${file.name} upload failed`,
        icon: "error",
      });
    }
  };

  const getCategoryList = () => {
    setLoading(true);
    try {
      getApi("/category/list").then((categoryList) => {
        setCategoryList(categoryList);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error while fetching category list:", error);
      throw error;
    }
  };

  return (
    <div>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner" />
        </div>
      )}
      <Flex
        gap="middle"
        align="center"
        justify={"space-between"}
        className="page-header"
      >
        <h2>Category Management</h2>
        <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: <a href="">Category Management</a>,
            },
          ]}
        />
      </Flex>
      <Flex vertical align="space-end">
        <Button
          shape="round"
          className="badge-btn-dark"
          style={{ width: "200px", marginTop: "20px", marginBottom: "10px" }}
          onClick={() => createCategoryModalOpen()}
          icon={<PlusCircleOutlined />}
        >
          Create New Category
        </Button>
        <Row>
          <Col span={6}>
            <Input.Search
              placeholder="Search.."
              onSearch={(value) => {
                setSearchedText(value);
              }}
              onChange={(e) => {
                setSearchedText(e.target.value);
              }}
              style={{ marginBottom: 8 }}
            />
          </Col>
        </Row>
        <Table
          bordered={true}
          rowKey="key"
          columns={columns}
          dataSource={categoryList}
        />
      </Flex>

      <Modal
        width="800px"
        styles={{ overflowY: "auto", maxHeight: "200px" }}
        title="Update Category"
        open={isUpdateModalVisible}
        onCancel={handleCloseUpdateModal}
        onOk={() => formUpdate.submit()}
        okText="Update"
      >
        <Form
          name="UpdateForm"
          form={formUpdate}
          onFinish={updateCategory}
          scrollToFirstError
          layout="vertical"
          autoComplete="off"
          initialValues={categoryData}
        >
          <Flex direction="horizontal">
            <div style={{ width: "50%", marginRight: "3%" }}>
              <Form.Item
                name="name"
                label="Category Name"
                rules={[
                  { required: true, message: "Please input category title!" },
                ]}
              >
                <Input placeholder="input category title" />
              </Form.Item>
              <Form.Item
                name="placementType"
                label="Placement Type"
                rules={[
                  { required: true, message: "Please input placement type!" },
                ]}
              >
                <Select placeholder="select category placement type">
                  <Option value="Carousel">Carousel</Option>
                  <Option value="Grid">Grid</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="tagMessage"
                label="Tag"
                textAA
                rules={[{ required: true, message: "Please input tag!" }]}
              >
                <Input />
              </Form.Item>
              <Flex>
                <Form.Item
                  name="tagFontColor"
                  className="ms-10"
                  label="Tag Font Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: "select tag font color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="#FFFFFF"
                    value={tagFontColor}
                    onChange={(color) =>
                      handleColorChange(color, setTagFontColor)
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="tagBackgroundColor"
                  className="ms-10"
                  label="Tag Background Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: "select tag background color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="#1E222B"
                    value={tagBackgroundColor}
                    onChange={(color) =>
                      handleColorChange(color, setTagBackgroundColor)
                    }
                  />
                </Form.Item>
              </Flex>

              <Form.Item
                name="infoMessage"
                label="Info Message"
                textAA
                rules={[
                  { required: false, message: "Please input info message!" },
                ]}
              >
                <TextArea />
              </Form.Item>
              <Flex>
                <Form.Item
                  name="infoFontColor"
                  className="ms-10"
                  label="Info Font Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: "select info font color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="#FFFFFF"
                    value={infoFontColor}
                    onChange={(color) =>
                      handleColorChange(color, setInfoFontColor)
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="infoBackgroundColor"
                  className="ms-10"
                  label="Info Background Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: "select info background color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="#1E222B"
                    value={infoBackgroundColor}
                    onChange={(color) =>
                      handleColorChange(color, setInfoBackgroundColor)
                    }
                  />
                </Form.Item>
              </Flex>
              <Form.Item
                name="buttonName"
                label="Button Name"
                rules={[
                  {
                    required: false,
                    message: "Please input Button Name!",
                  },
                ]}
              >
                <Input placeholder="input button name" />
              </Form.Item>
              <Form.Item
                name="buttonLinkURL"
                label="Button URL"
                rules={[
                  {
                    required: false,
                    message: "Please input Button URL!",
                  },
                ]}
              >
                <Input addonBefore="https://" />
              </Form.Item>
              <Flex>
                <Form.Item
                  name="buttonColor"
                  className="ms-5"
                  label="Button Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: " select button color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="white"
                    value={buttonColor}
                    onChange={(color) =>
                      handleColorChange(color, setButtonColor)
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="buttonTextColor"
                  className="ms-5"
                  label="Button Text Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: "Select button text color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="white"
                    value={buttonColor}
                    onChange={(color) =>
                      handleColorChange(color, setButtonTextColor)
                    }
                  />
                </Form.Item>
              </Flex>
            </div>
          </Flex>
          <Flex>
            <Form.Item
              className="ms-5"
              name="iconURL"
              label="Icon"
              rules={[
                { required: false, message: "Please select header icon!" },
              ]}
            >
              <Upload
                listType="text"
                onRemove="true"
                name="iconURL"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={uploadImage}
                beforeUpload={false}
                onChange={handleIconChange}
              >
                {iconURL ? (
                  <div>
                    <Image
                      src={iconURL}
                      alt="Icon"
                      width={35}
                      preview={false}
                      style={{
                        background: "radial-gradient(#3d5ed1, transparent)",
                        borderRadius: "50%",
                        width: "50px;",
                        padding: "5px",
                      }}
                    />
                  </div>
                ) : (
                  <Button icon={<UploadOutlined />}>Upload SVG</Button>
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              className="ms-5"
              name="backgroundImageURL"
              label="Background Image"
              rules={[
                { required: false, message: "Please select header icon!" },
              ]}
            >
              <Upload
                listType="text"
                onRemove="true"
                name="backgroundImageURL"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={uploadImage}
                beforeUpload={false}
                onChange={handleBackgroundImageChange}
              >
                {backgroundImageURL ? (
                  <>
                    <Image
                      preview={false}
                      src={backgroundImageURL}
                      alt="Icon"
                      width={50}
                    />
                    <Button
                      danger
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={removeBackgroundImage}
                      style={{ marginLeft: 8 }}
                    ></Button>
                  </>
                ) : (
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                )}
              </Upload>
            </Form.Item>

            <Form.Item
              className="ms-10"
              name="lineColor"
              label="Line Color"
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
              rules={[
                {
                  required: false,
                  message: " select line color!",
                },
              ]}
            >
              <ColorPicker
                showText
                defaultValue="white"
                value={lineColor}
                onChange={(color) => handleColorChange(color, setLineColor)}
              />
            </Form.Item>
          </Flex>
          <Flex>
            <Flex>
              <Form.Item
                className="ms-5"
                name="font"
                style={{ width: "200px" }}
                label="Font"
                rules={[{ required: false, message: "Please select font!" }]}
              >
                <Select placeholder="select font">
                  <Option value="Poppins">Poppins</Option>
                  <Option value="Roboto">Roboto</Option>
                  <Option value="Custom">Custom</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="fontColor"
                className="ms-5"
                label="Font Color"
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
                rules={[
                  {
                    required: false,
                    message: "select font color!",
                  },
                ]}
              >
                <ColorPicker
                  showText
                  defaultValue="white"
                  value={fontColor}
                  onChange={(color) => handleColorChange(color, setFontColor)}
                />
              </Form.Item>
            </Flex>
            <Flex>
              <Form.Item
                className="ms-10"
                name="shape"
                label="Shape"
                rules={[{ required: true, message: "Please select a shape!" }]}
              >
                <Radio.Group>
                  <Radio.Button value="Rounded">Rounded</Radio.Button>
                  <Radio.Button value="Square">Square</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Flex>
          </Flex>
          <Flex>
            <Form.Item
              className="ms-10"
              name="layout"
              label="Layout"
              rules={[
                {
                  required: false,
                  message: "Please input category title!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => handleRadioChange(e.target.value)}
                value={layoutValue}
              >
                <Radio value="fullWidth"> Full Width </Radio>
                <Radio value="padding"> Padding </Radio>
              </Radio.Group>
            </Form.Item>

            <Flex vertical>
              <Flex>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Flex>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        width: "250px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "8px" }}>
                        <Form.Item
                          name="paddingTop"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            addonBefore={<ArrowUpOutlined />}
                            placeholder="Top"
                            type="number"
                            onChange={(e) =>
                              handlePaddingInputChange(e.target.value, "top")
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name="paddingRight"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            addonBefore={<ArrowRightOutlined />}
                            placeholder="Right"
                            type="number"
                            onChange={(e) =>
                              handlePaddingInputChange(e.target.value, "right")
                            }
                          />
                        </Form.Item>
                      </div>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <Form.Item
                          name="paddingBottom"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            addonBefore={<ArrowDownOutlined />}
                            placeholder="Bottom"
                            type="number"
                            onChange={(e) =>
                              handlePaddingInputChange(e.target.value, "bottom")
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name="paddingLeft"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            addonBefore={<ArrowLeftOutlined />}
                            placeholder="Left"
                            type="number"
                            onChange={(e) =>
                              handlePaddingInputChange(e.target.value, "left")
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div
                      className="ms-10"
                      style={{
                        width: "75px",
                        height: "75px",
                        border: "1px dotted white",
                        borderRadius: "2px",
                        padding: `${paddingValues.top}px ${paddingValues.right}px ${paddingValues.bottom}px ${paddingValues.left}px`,
                        backgroundColor: "#1677FF",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                          width: "100%",
                          borderRadius: "2px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <BorderOuterOutlined
                          style={{ fontSize: "50px", color: "lightGray" }}
                        />
                      </div>
                    </div>
                  </Flex>
                </Form.Item>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      </Modal>

      <Modal
        width="800px"
        title="Create Category"
        open={isCreateModalVisible}
        onCancel={handleCloseCreateModal}
        onOk={() => formCreate.submit()}
        okText="Create"
      >
        <Form
          form={formCreate}
          onFinish={createCategory}
          scrollToFirstError
          layout="vertical"
          autoComplete="off"
        >
          <Flex direction="horizontal">
            <div style={{ width: "50%", marginRight: "3%" }}>
              <Form.Item
                name="name"
                label="Category Name"
                rules={[
                  { required: true, message: "Please input category title!" },
                ]}
              >
                <Input placeholder="input category title" />
              </Form.Item>
              <Form.Item
                name="placementType"
                label="Placement Type"
                rules={[
                  { required: true, message: "Please input placement type!" },
                ]}
              >
                <Select placeholder="select category placement type">
                  <Option value="Carousel">Carousel</Option>
                  <Option value="Grid">Grid</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="tagMessage"
                label="Tag"
                textAA
                rules={[{ required: true, message: "Please input tag!" }]}
              >
                <TextArea />
              </Form.Item>
              <Flex>
                <Form.Item
                  name="tagFontColor"
                  className="ms-10"
                  label="Tag Font Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: "select tag font color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="#FFFFFF"
                    value={tagFontColor}
                  />
                </Form.Item>
                <Form.Item
                  name="tagBackgroundColor"
                  className="ms-10"
                  label="Tag Background Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: "select tag background color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="#1E222B"
                    value={tagBackgroundColor}
                  />
                </Form.Item>
              </Flex>

              <Form.Item
                name="infoMessage"
                label="Info Message"
                textAA
                rules={[
                  { required: false, message: "Please input info message!" },
                ]}
              >
                <TextArea />
              </Form.Item>
              <Flex>
                <Form.Item
                  name="infoFontColor"
                  className="ms-10"
                  label="Info Font Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: "select info font color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="#FFFFFF"
                    value={infoFontColor}
                  />
                </Form.Item>
                <Form.Item
                  name="infoBackgroundColor"
                  className="ms-10"
                  label="Info Background Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: "select info background color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="#1E222B"
                    value={infoBackgroundColor}
                  />
                </Form.Item>
              </Flex>
              <Form.Item
                name="buttonName"
                label="Button Name"
                rules={[
                  {
                    required: false,
                    message: "Please input Button Name!",
                  },
                ]}
              >
                <Input placeholder="input button name" />
              </Form.Item>
              <Form.Item
                name="buttonLinkURL"
                label="Button URL"
                rules={[
                  {
                    required: false,
                    message: "Please input Button URL!",
                  },
                ]}
              >
                <Input addonBefore="https://" />
              </Form.Item>
              <Flex>
                <Form.Item
                  name="buttonColor"
                  className="ms-5"
                  label="Button Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: " select button color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="white"
                    value={buttonColor}
                    onChange={(color) =>
                      handleColorChange(color, setButtonColor)
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="buttonTextColor"
                  className="ms-5"
                  label="Button Text Color"
                  getValueFromEvent={(color) => {
                    return "#" + color.toHex();
                  }}
                  rules={[
                    {
                      required: false,
                      message: "Select button text color!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    defaultValue="white"
                    value={buttonColor}
                    onChange={(color) =>
                      handleColorChange(color, setButtonTextColor)
                    }
                  />
                </Form.Item>
              </Flex>
            </div>
          </Flex>
          <Flex>
            <Form.Item
              className="ms-5"
              name="iconURL"
              label="Icon"
              rules={[
                { required: false, message: "Please select header icon!" },
              ]}
            >
              <Upload
                listType="text"
                onRemove="true"
                name="iconURL"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={uploadImage}
                beforeUpload={false}
                onChange={handleIconChange}
              >
                {iconURL ? (
                  <Image src={iconURL} alt="Icon" width={35} preview={false} />
                ) : (
                  <Button icon={<UploadOutlined />}>Upload SVG</Button>
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              className="ms-5"
              name="backgroundImageURL"
              label="Background Image"
              rules={[
                { required: false, message: "Please select header icon!" },
              ]}
            >
              <Upload
                listType="text"
                onRemove="true"
                name="backgroundImageURL"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={uploadImage}
                beforeUpload={false}
                onChange={handleBackgroundImageChange}
              >
                {backgroundImageURL ? (
                  <Image
                    preview={false}
                    src={backgroundImageURL}
                    alt="Icon"
                    width={35}
                  />
                ) : (
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                )}{" "}
              </Upload>
            </Form.Item>

            <Form.Item
              name="lineColor"
              className="ms-5"
              label="Line Color"
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
              rules={[
                {
                  required: false,
                  message: "select line color!",
                },
              ]}
            >
              <ColorPicker showText defaultValue="#1E222B" value={lineColor} />
            </Form.Item>
          </Flex>
          <Flex>
            <Flex>
              <Form.Item
                className="ms-5"
                name="font"
                style={{ width: "200px" }}
                label="Font"
                rules={[{ required: false, message: "Please select font!" }]}
              >
                <Select placeholder="select font">
                  <Option value="Poppins">Poppins</Option>
                  <Option value="Roboto">Roboto</Option>
                  <Option value="Custom">Custom</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="fontColor"
                className="ms-5"
                label="Font Color"
                getValueFromEvent={(color) => {
                  return "#" + color.toHex();
                }}
                rules={[
                  {
                    required: false,
                    message: " select font color!",
                  },
                ]}
              >
                <ColorPicker showText format={formatHex} />
              </Form.Item>
            </Flex>
            <Flex>
              <Form.Item
                className="ms-10"
                name="shape"
                label="Shape"
                rules={[{ required: true, message: "Please select a shape!" }]}
              >
                <Radio.Group>
                  <Radio.Button value="Rounded">Rounded</Radio.Button>
                  <Radio.Button value="Square">Square</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Flex>
          </Flex>
          <Flex>
            <Form.Item
              className="ms-10"
              name="layout"
              label="Layout"
              rules={[
                {
                  required: false,
                  message: "Please input category title!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => handleRadioChange(e.target.value)}
                value={layoutValue}
              >
                <Radio value="fullWidth"> Full Width </Radio>
                <Radio value="padding"> Padding </Radio>
              </Radio.Group>
            </Form.Item>

            <Flex vertical>
              <Flex>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Flex>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        width: "250px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "8px" }}>
                        <Form.Item
                          name="paddingTop"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            addonBefore={<ArrowUpOutlined />}
                            placeholder="Top"
                            type="number"
                            onChange={(e) =>
                              handlePaddingInputChange(e.target.value, "top")
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name="paddingRight"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            addonBefore={<ArrowRightOutlined />}
                            placeholder="Right"
                            type="number"
                            onChange={(e) =>
                              handlePaddingInputChange(e.target.value, "right")
                            }
                          />
                        </Form.Item>
                      </div>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <Form.Item
                          name="paddingBottom"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            addonBefore={<ArrowDownOutlined />}
                            placeholder="Bottom"
                            type="number"
                            onChange={(e) =>
                              handlePaddingInputChange(e.target.value, "bottom")
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name="paddingLeft"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            addonBefore={<ArrowLeftOutlined />}
                            placeholder="Left"
                            type="number"
                            onChange={(e) =>
                              handlePaddingInputChange(e.target.value, "left")
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div
                      className="ms-10"
                      style={{
                        width: "75px",
                        height: "75px",
                        border: "1px dotted white",
                        borderRadius: "2px",
                        padding: `${paddingValues.top}px ${paddingValues.right}px ${paddingValues.bottom}px ${paddingValues.left}px`,
                        backgroundColor: "#1677FF",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                          width: "100%",
                          borderRadius: "2px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <BorderOuterOutlined
                          style={{ fontSize: "50px", color: "lightGray" }}
                        />
                      </div>
                    </div>
                  </Flex>
                </Form.Item>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      </Modal>

      <Modal
        width="800px"
        title={"Badges"}
        open={isBadgeModalVisible}
        onCancel={() => setIsBadgeModalVisible(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => setIsBadgeModalVisible(false)}
          >
            close
          </Button>,
        ]}
      >
        <SortableTable slides={categoryBadges} />
      </Modal>
    </div>
  );
};

export default Dashboard;
