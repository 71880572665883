import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  Flex,
  Row,
  Col,
  Space,
  Image,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  Empty,
  ColorPicker,
  message,
  Switch,
  Tabs,
  Badge,
  Modal,
} from "antd";
import {
  PlusOutlined,
  StarOutlined,
  EyeOutlined,
  UploadOutlined,
  LoadingOutlined,
  DatabaseOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { getApi, postApi, getParamsApi } from "../services/api.jsx";
import magic from "../assets/img/magic.png";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import "react-quill/dist/quill.snow.css";
import TabPane from "antd/es/tabs/TabPane.js";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const s3BaseURL = process.env.REACT_APP_S3_BASE_URL;

const BadgeEdit = () => {
  const { Option } = Select;
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [checkedReward, setCheckedReward] = useState(false);
  const [buttonColor, setButtonColor] = useState("#F35454");
  const [buttonTextColor, setButtonTextColor] = useState("#ffffff");
  const [fontColor, setFontColor] = useState("#ffffff");
  const [backgroundColor, setBackgroundColor] = useState("#0E0F11");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [backgroundImageURL, setBackgroundImageURL] = useState();
  const [badgeStatus, setBadgeStatus] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [type, setType] = useState([]);
  const [badgeData, setBadgeData] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [dataSourceList, setDataSourceList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formValues, setFormValues] = useState();
  const [recordCount, setRecordCount] = useState(0);

  const { id } = useParams();
  const getBadgeData = (badgeId) => {
    setLoading(true);
    const params = { id: badgeId };
    getParamsApi(`/badge/${badgeId}`, params)
      .then((response) => {
        console.log("Badge data:", response);
        setBadgeData(response);
        setLoading(false);
        setImageUrl(response.imageURL);
      })
      .catch((error) => {
        console.error("Error fetching badge data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBadgeData(id);
  }, [id]);

  useEffect(() => {
    getDataSourceList();
  }, []);

  const getDataSourceList = () => {
    getApi("/badge/datasource")
      .then((dataSources) => {
        setDataSourceList(dataSources);
      })
      .catch((ex) => {
        console.error("Error fetching datasource list:", ex);
      });
  };

  useEffect(() => {
    const hasReward = badgeData.rewardDescription !== null;
    setCheckedReward(hasReward);
    const formData = {
      ...badgeData,
      publishDate: badgeData.publishDate
        ? moment(badgeData.publishDate)
        : undefined,
      expireDate: badgeData.expireDate
        ? moment(badgeData.expireDate)
        : undefined,
      hasReward: hasReward,
    };
    form.setFieldsValue(formData);
  }, [form, badgeData]);

  const handleColorChange = (color, setter) => {
    const hexValue = typeof color === "string" ? color : color?.toHexString();
    setter(hexValue);
  };
  const handleCategoryChange = (value) => {
    const selectedCategoryName = value;
    const selectedCategory = categoryList.find(
      (category) => category.name === selectedCategoryName
    );
    if (selectedCategory) {
      const selectedCategoryId = selectedCategory.id;
      setCategoryId(selectedCategoryId);
      console.log("Selected Category ID:", selectedCategoryId);
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/WEBP file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const [previewData, setPreviewData] = useState({
    headline: "",
    description: "",
    imageURL: "",
    videoURL: "",
    bacgroundColor: "",
    font: "",
    fontColor: "",
    buttonLinkURL: "",
    buttonName: "",
    buttonColor: "",
    buttonTextColor: "",
  });

  const onRewardChange = (checked) => {
    console.log(`checked = ${checked}`);
    setCheckedReward(checked);
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "code"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const TextEditor = ({ value, onChange, placeholder }) => {
    return (
      <>
        <ReactQuill
          theme="snow"
          value={value || ""}
          modules={modules}
          formats={formats}
          onChange={onChange}
          placeholder={placeholder}
        />
      </>
    );
  };

  const getCategoryList = () => {
    getApi("/category/list").then((categoryList) => {
      setCategoryList(categoryList);
    });
  };

  const handleFormSubmit = (values) => {
    let updatedValues = { ...badgeData, ...values };
    setFormValues(values);
    let criteriaCountURL = "/badge/criteriaCount?";
    if (updatedValues.dataSource) {
      criteriaCountURL =
        criteriaCountURL + "dataSource=" + updatedValues.dataSource;
    }
    if (updatedValues.selectionCriteria) {
      criteriaCountURL =
        criteriaCountURL +
        "&selectionCriteria=" +
        updatedValues.selectionCriteria;
    }
    if (updatedValues.suppressionCriteria) {
      criteriaCountURL =
        criteriaCountURL +
        "&suppressionCriteria=" +
        updatedValues.suppressionCriteria;
    }
    setLoading(true);
    getApi(criteriaCountURL)
      .then((count) => {
        setRecordCount(count);
        setIsModalVisible(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Error on checking criteria record count",
          error,
          icon: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const categoryUpdatedId = categoryId ? categoryId : badgeData.categoryId;

  const updateBadge = () => {
    const values = {
      ...formValues,
      publishDate: formValues.publishDate
        ? formValues.publishDate.toDate()
        : undefined,
      expireDate: formValues.expireDate
        ? moment(formValues.expireDate.format("YYYY-MM-DD")).toDate()
        : undefined,
    };
    console.log("Selected categoryName:", type);
    setLoading(true);
    const updatedBadgeData = {
      ...badgeData,
      ...values,
      //isActive: true,
      lastUpdatedBy: localStorage.getItem("userEmail"),
      imageURL: imageUrl,
      backgroundImageURL: backgroundImageURL,
      categoryId: categoryUpdatedId,
    };
    //Base64 Control
    if ("imageURL" && "backgroundImageURL" in values) {
      delete values.imageUrl;
      delete values.backgroundImageURL;
    }
    postApi("/badge", updatedBadgeData)
      .then((data) => {
        console.log(data);
        Swal.fire({
          title: "Success!",
          text: "Badge Updated!",
          icon: "success",
        });
        /*         navigate("/dashboard");
         */
      })
      .catch((error) => {
        console.error("Error updating badge:", error);
        Swal.fire({
          title: "Error!",
          text: "Error updating badge",
          error,
          icon: "error",
        });
      })
      .finally(() => {
        setLoading(false);
        setIsModalVisible(false);
      });
  };

  const badgePreview = () => {
    form
      .validateFields([
        "headline",
        "description",
        "imageURL",
        "backgroundImageURL",
        "videoURL",
        "buttonName",
        "buttonTextColor",
        "buttonColor",
        "buttonLinkURL",
      ])
      .then((values) => {
        setPreviewData(values);
      })
      .catch((error) => {
        console.log("Error in form:", error);
      });
  };

  const badgeFormStatusControl = () => {
    const values = form.getFieldsValue(["dataSource", "selectionCriteria"]);
    if (values.dataSource && values.selectionCriteria) {
      setBadgeStatus("success");
    } else {
      setBadgeStatus("error");
    }
  };

  const handleFormValuesChange = () => {
    badgePreview();
    badgeFormStatusControl();
  };
  const handleBadgeImageChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        let imageUrl = s3BaseURL + info.file.originFileObj.name;
        setImageUrl(imageUrl);
      });
    }
  };
  const handleBackgroundImageChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        let backgroundImageURL = s3BaseURL + info.file.originFileObj.name;
        setBackgroundImageURL(backgroundImageURL);
      });
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const uploadImage = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      postApi("/file", formData, "multipart/form-data").then((data) => {
        console.log(data);
        onSuccess(data, file);
      });
      Swal.fire({
        title: "Success!",
        text: `${file.name} uploaded successfully`,
        icon: "success",
      });
    } catch (error) {
      onError(error);
      Swal.fire({
        title: "Error!",
        text: `${file.name} upload failed`,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);
  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      if (!isPNG) {
        message.error(`${file.name} is not a png file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    },
  };
  const handleRemoveBackgroundImage = () => {
    setBackgroundImageURL("");
  };

  return (
    <div>
      <Flex
        gap="middle"
        align="center"
        justify="space-between"
        className="page-header"
      >
        <h2>
          {" "}
          <EditOutlined /> {badgeData.name}
        </h2>
        <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: <a href="">Badge Editor</a>,
            },
          ]}
        />
      </Flex>

      <Row style={{ padding: "20px 10px" }}>
        <Col span={16}>
          <Form
            form={form}
            onFinish={handleFormSubmit}
            onValuesChange={handleFormValuesChange}
            scrollToFirstError
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            initialValues={badgeData}
          >
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={
                  <span>
                    <StarOutlined className="me-5" />
                    Badge Info
                  </span>
                }
                key="1"
              >
                <Flex>
                  <Flex style={{ width: "45%", marginRight: "3%" }} vertical>
                    <Form.Item
                      name="imageURL"
                      label="Badge Image"
                      rules={[
                        { required: true, message: "Please upload image!" },
                      ]}
                    >
                      <Upload
                        onRemove="true"
                        name="imageURL"
                        listType="picture-circle"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={uploadImage}
                        beforeUpload={beforeUpload}
                        onChange={handleBadgeImageChange}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name="id"
                      value="sdfsdfsd"
                      rules={[
                        {
                          required: true,
                          message: "Please input badge number!",
                        },
                      ]}
                    >
                      <Input defaultValue={badgeData.id} disabled />
                    </Form.Item>
                    <Form.Item
                      name="name"
                      label="Badge Name"
                      rules={[
                        { required: true, message: "Please input badge name!" },
                      ]}
                    >
                      <Input placeholder="input badge name" />
                    </Form.Item>
                    <Form.Item
                      name="headline"
                      label="Headline"
                      rules={[
                        { required: true, message: "Please enter headline!" },
                      ]}
                    >
                      <Input
                        placeholder="input headline"
                        value={badgeData.headline}
                      />
                    </Form.Item>
                    <Form.Item
                      name="type"
                      label="Category"
                      rules={[
                        { required: true, message: "Please select category!" },
                      ]}
                    >
                      <Select
                        placeholder="Select badge category"
                        onChange={(value) => handleCategoryChange(value)}
                      >
                        {categoryList.map((category) => (
                          <Option key={category.id} value={category.name}>
                            {category.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="description"
                      label="Badge Description"
                      maxLength="100"
                      rules={[
                        {
                          required: true,
                          message: "Please input badge description!",
                        },
                      ]}
                    >
                      <TextEditor />
                    </Form.Item>
                    <Form.Item
                      name="hoverDescription"
                      label="Hover Badge Description"
                    >
                      <TextEditor />
                    </Form.Item>
                  </Flex>

                  <Flex vertical>
                    <Form.Item name="videoURL" label="Video/Image URL">
                      <Input placeholder="input embed video URL" />
                    </Form.Item>

                    <Form.Item name="altText" label="Alt Text">
                      <Input placeholder="input alt text " />
                    </Form.Item>
                    <Flex>
                      <Form.Item
                        name="backgroundColor"
                        className="ms-5"
                        label="Backround Color"
                        getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }}
                        rules={[
                          {
                            required: false,
                            message: "select background color!",
                          },
                        ]}
                      >
                        <ColorPicker
                          showText
                          defaultValue="#1E222B"
                          value={backgroundColor}
                          onChange={(color) =>
                            handleColorChange(color, setBackgroundColor)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        className="ms-5"
                        name="backgroundImageURL"
                        label="Background Image"
                        rules={[
                          { required: false, message: "Please select font!" },
                        ]}
                      >
                        <Upload
                          {...props}
                          showRemoveIcon={true}
                          onRemove={() => handleRemoveBackgroundImage()}
                          name="backgroundImageURL"
                          showUploadList={true}
                          customRequest={uploadImage}
                          beforeUpload={beforeUpload}
                          onChange={handleBackgroundImageChange}
                        >
                          <Button icon={<UploadOutlined />}>
                            Upload Image
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Flex>

                    <Flex>
                      <Form.Item
                        name="font"
                        style={{ width: "200px" }}
                        label="Font"
                        rules={[
                          { required: false, message: "Please select font!" },
                        ]}
                      >
                        <Select placeholder="select font">
                          <Option value="Poppins">Poppins</Option>
                          <Option value="Roboto">Roboto</Option>
                          <Option value="Custom">Custom</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="fontColor"
                        className="ms-5"
                        label="Font Color"
                        getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }}
                        rules={[
                          {
                            required: false,
                            message: " select font color!",
                          },
                        ]}
                      >
                        <ColorPicker
                          showText
                          defaultValue="white"
                          value={fontColor}
                          onChange={(color) =>
                            handleColorChange(color, setFontColor)
                          }
                        />
                      </Form.Item>
                    </Flex>

                    <Form.Item
                      name="buttonName"
                      label="Button Name"
                      rules={[
                        {
                          required: false,
                          message: "Please input Button Name!",
                        },
                      ]}
                    >
                      <Input placeholder="input button name" />
                    </Form.Item>
                    <Form.Item
                      name="buttonLinkURL"
                      label="Button URL"
                      rules={[
                        {
                          required: false,
                          message: "Please input Button URL!",
                        },
                      ]}
                    >
                      <Input addonBefore="https://" />
                    </Form.Item>
                    <Flex>
                      <Form.Item
                        name="buttonColor"
                        className="ms-5"
                        label="Button Color"
                        getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }}
                        rules={[
                          {
                            required: false,
                            message: " select button color!",
                          },
                        ]}
                      >
                        <ColorPicker
                          showText
                          defaultValue="white"
                          value={buttonColor}
                          onChange={(color) =>
                            handleColorChange(color, setButtonColor)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        name="buttonTextColor"
                        className="ms-5"
                        label="Button Text Color"
                        getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }}
                        rules={[
                          {
                            required: false,
                            message: "Select button text color!",
                          },
                        ]}
                      >
                        <ColorPicker
                          showText
                          defaultValue="white"
                          value={buttonColor}
                          onChange={(color) =>
                            handleColorChange(color, setButtonTextColor)
                          }
                        />
                      </Form.Item>
                    </Flex>
                    <Form.Item
                      name="hasReward"
                      label="Is there a reward for this badge?"
                    >
                      <Switch
                        onChange={onRewardChange}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                      />
                    </Form.Item>
                    {checkedReward && (
                      <Form.Item
                        name="rewardDescription"
                        label="Reward Description"
                        rules={[
                          {
                            required: true,
                            message: "Please input reward description!",
                          },
                        ]}
                      >
                        <Input placeholder="Badge Reward Description" />
                      </Form.Item>
                    )}
                    <Flex>
                      <Form.Item
                        className="me-5"
                        name="publishDate"
                        label="Publish Date"
                        rules={[
                          {
                            required: true,
                            message: "Please select publish date!",
                          },
                        ]}
                      >
                        <DatePicker showTime maxDate={dayjs('2028-01-01')} />
                      </Form.Item>
                      <Form.Item
                        label="Expire Date"
                        name="expireDate"
                        rules={[
                          {
                            required: false,
                            message: "Please select expiry date!",
                          },
                        ]}
                      >
                        <DatePicker maxDate={dayjs('2099-01-01')} />
                      </Form.Item>
                    </Flex>
                    <Form.Item>
                      <Space>
                        <Button
                          type="dashed"
                          shape="round"
                          danger
                          onClick={badgePreview}
                          icon={<EyeOutlined />}
                        >
                          Preview
                        </Button>
                        <Button
                          shape="round"
                          className="badge-btn-dark"
                          type="primary"
                          success
                          htmlType="submit"
                          icon={<StarOutlined />}
                        >
                          Update
                        </Button>
                        <Button htmlType="reset" shape="round" danger>
                          Reset
                        </Button>
                      </Space>
                    </Form.Item>
                  </Flex>
                </Flex>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <DatabaseOutlined className="me-5" />
                    Data
                    <Badge
                      status={badgeStatus}
                      text={
                        badgeStatus == "error" ? (
                          <span style={{ fontStyle: "italic", color: "red" }}>
                            Please enter data into the fields before saving!
                          </span>
                        ) : (
                          ""
                        )
                      }
                      className="ms-5"
                    ></Badge>
                  </span>
                }
                key="2"
              >
                <Flex style={{ width: "50%" }} vertical>
                  <Form.Item
                    name="dataSource"
                    label="Datasource"
                    rules={[
                      { required: true, message: "Please select datasource!" },
                    ]}
                  >
                    <Select placeholder="Select datasource">
                      {dataSourceList.map((dataSource) => (
                        <Option
                          key={dataSource}
                          value={dataSource}
                          label={dataSource}
                        >
                          {dataSource}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="selectionCriteria"
                    label="Selection Criteria"
                    rules={[
                      { required: true, message: "Please selection criteria!" },
                    ]}
                  >
                    <Input placeholder="Select criteria" />
                  </Form.Item>
                  <Form.Item
                    name="suppressionCriteria"
                    label="Suppression Criteria"
                  >
                    <Input placeholder="Suppression Criteria" />
                  </Form.Item>
                  <Form.Item
                    name="badgeStatusCalculation"
                    label="Badge Status Calculation"
                  >
                    <Input placeholder="Badge Status Calculation" />
                  </Form.Item>
                  <Form.Item
                    name="badgeStatusCompletionValue"
                    label="Badge Status Completion Value"
                  >
                    <Input placeholder="Badge Status Calculation" />
                  </Form.Item>
                  <Form.Item>
                    <Space>
                      <Button
                        shape="round"
                        className="badge-btn-dark"
                        type="primary"
                        success
                        htmlType="submit"
                        icon={<StarOutlined />}
                      >
                        Update
                      </Button>
                      <Button htmlType="reset" shape="round" danger>
                        Reset
                      </Button>
                    </Space>
                  </Form.Item>
                </Flex>
              </TabPane>
            </Tabs>
          </Form>
        </Col>
        <Col span={8}>
          <Flex
            style={{
              backgroundImage: backgroundImageURL
                ? `url(${backgroundImageURL})`
                : "none",
              backgroundSize: "cover",
              backgroundColor: backgroundColor,
              color: fontColor,
            }}
            className="w-100 h-100 rounded-10"
            vertical
            justify="space-between"
          >
            {previewData.headline || badgeData.headline ? (
              <>
                <div className="text-center p-15">
                  <h3>Magic Badge Preview</h3>
                  <Image
                    src={previewData.imageURL || badgeData.imageURL}
                    alt="Badge"
                    style={{
                      width: "150px",
                    }}
                  />
                  <h3>{previewData.headline || badgeData.headline}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: previewData.description || badgeData.description,
                    }}
                  />
                  {
                    (previewData.buttonName || badgeData.buttonName) && (
                      <Button
                        href={previewData.buttonLinkURL || badgeData.buttonLinkURL}
                        style={{
                          backgroundColor: previewData.buttonColor || badgeData.buttonColor,
                          color: previewData.buttonTextColor || badgeData.buttonTextColor,
                          borderColor: previewData.buttonTextColor || badgeData.buttonTextColor,
                        }}
                      >
                        {
                          previewData.buttonName || badgeData.buttonName
                        }
                      </Button>
                    )
                  }
                </div>
                <div>
                  {(previewData.videoURL || badgeData.videoURL) && (
                    <iframe
                      className="prev-iframe"
                      width="100%"
                      height="315"
                      src={previewData.videoURL || badgeData.videoURL}
                      title={previewData.name || badgeData.name}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  )}
                </div>
              </>
            ) : (
              <div className="flex-empty">
                <Empty
                  className=""
                  image={magic}
                  imageStyle={{ height: 150 }}
                  description={<span>Fill Out the Form and Click Preview</span>}
                ></Empty>
              </div>
            )}
          </Flex>
        </Col>

      </Row>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner" />
        </div>
      )}
      <Modal
        /*         width="800px"
         */ title={
          <>
            <InfoCircleOutlined style={{ color: "red" }} />
            {"\tConfirmation"}
          </>
        }
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => updateBadge()}
        okText={"Update Badge"}
      >
        <p>
          There are {recordCount} records with these criterias. Do you want to
          proceed to save badge with these criterias?
        </p>
      </Modal>
    </div>
  );
};

export default BadgeEdit;
