import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counterSlice';
import imageReducer from '../features/imageSlice';

const store = configureStore({
  reducer: {
    counter: counterReducer,
    image: imageReducer,
  },
});

export default store;
