import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (error, errorInfo) => {
      console.error("Service call error:", error, errorInfo);
      setHasError(true);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    };

    const handleWindowError = (event) => {
      handleError(event.error || new Error("Undefined error"), null);
    };

    window.addEventListener("error", handleWindowError);

    return () => {
      window.removeEventListener("error", handleWindowError);
    };
  }, []);
  if (hasError) {
    return <p>Something went wrong!</p>;
  }

  return children;
};

export default ErrorBoundary;
