import React from "react";
import "grapesjs/dist/css/grapes.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import MainLayout from "./components/mainLayout";
import LoginPage from "./pages/loginPage";
import Dashboard from "./pages/dashboard";
import BadgeEditor from "./pages/badgeEditor";
import CategoryManagement from "./pages/categoryManagement";
import UserManagement from "./pages/userManagement";
import BadgePreview from "./pages/badgePreview";
import BadgeEdit from "./pages/badgeEdit";
import ChecklistPage from "./pages/checklistPage";
import InfoMessage from "./pages/infoMessage";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<LoginPage />}>
          {" "}
        </Route>
        {
          <Route element={<MainLayout />}>
            <Route path="/dashboard" element={<Dashboard />}>
              {" "}
            </Route>
            <Route path="/badge-editor" element={<BadgeEditor />}>
              {" "}
            </Route>
            <Route path="/landing-editor" element={<BadgeEditor />}>
              {" "}
            </Route>
            <Route path="/category-management" element={<CategoryManagement />}>
              {" "}
            </Route>
            <Route path="/info-message-management" element={<InfoMessage />}>
              {" "}
            </Route>
            <Route path="/user-management" element={<UserManagement />}>
              {" "}
            </Route>
            <Route path="/checklist-management" element={<ChecklistPage />}>
              {" "}
            </Route>
            <Route path="/badge-preview/:id" element={<BadgePreview />}>
              {" "}
            </Route>
            <Route path="/badge-edit/:id" element={<BadgeEdit />}>
              {" "}
            </Route>
            <Route path="/badge-preview/:id" element={<BadgePreview />}>
              {" "}
            </Route>
          </Route>
        }
      </Routes>
    </div>
  );
}

export default App;
