import React, { useEffect } from "react";
import Login from "../components/login";
import { Image } from "antd";
import LoginSider from "../assets/img/loginSider.svg";
import LoginLogo from "../assets/img/loginLogo.svg";
import { Flex } from "antd";
import { postApiWithoutRedirect } from "../services/api";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    validateToken();
  }, []);

  const validateToken = () => {
    if (localStorage.getItem("token")) {
      postApiWithoutRedirect("/auth/token/validate")
        .then(() => {
          navigate("/dashboard");
        })
        .catch((ex) => {
          console.error("Invalid token");
        });
    }
  };

  return (
    <Flex>
      <div
        style={{
          flex: "1",
          background: `url(${LoginSider}) no-repeat center center / cover`,
          height: "100vh",
        }}
      ></div>

      <Flex
        style={{
          flex: "1",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image preview={false} src={LoginLogo} />
        <h2 style={{ color: "#08184F" }}>Hello again!</h2>
        <Login />
        <div
          style={{
            position: "absolute",
            bottom: "0",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <p>© 2024 Orlando Magic Badge App</p>
        </div>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
