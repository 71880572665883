import React, { useState, useEffect } from "react";
import { Flex, Card, Row, Col, Statistic, Button, Breadcrumb } from "antd";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { getApi, postApi } from "../services/api.jsx";
import BadgeList from "./badgeList";
const formatter = (value) => <CountUp end={value} separator="," />;
const Dashboard = () => {
  const [activeBadgeCount, setActiveBadgeCount] = useState(0);
  const [passiveBadgeCount, setPassiveBadgeCount] = useState(0);

  useEffect(() => {
    fetchActivePassiveBadgeCounts();
  });

  const fetchActivePassiveBadgeCounts = () => {
    getApi("/badge/activePassiveCount")
      .then((res) => {
        setActiveBadgeCount(res.activeCount);
        setPassiveBadgeCount(res.passiveCount);
      })
      .catch((ex) => {
        console.error("Error fetching datasource list:", ex);
      });
  };

  return (
    <div>
      <Flex
        gap="middle"
        align="center"
        justify="space-between"
        className="page-header"
      >
        <h2>Home</h2>
        <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: <a href="">Badge List</a>,
            },
          ]}
        />
      </Flex>
      <Flex vertical>
        <Row gutter={[16, 16]} className="mb-5 mt-5">
          <Col sxs={24} sm={12} md={12} lg={12}>
            <Card bordered={true} actionsBg="#333">
              <Flex justify="space-around" direction="column" align="center">
                <Statistic
                  title="Active Badges"
                  value={activeBadgeCount}
                  valueStyle={{ color: "#3f8600" }}
                  formatter={formatter}
                  prefix={<ArrowUpOutlined />}
                />
                <Link to="/badge-editor">
                  <Button
                    style={{ marginTop: 16 }}
                    shape="round"
                    className="badge-btn-dark"
                    type="primary"
                    success
                    htmlType="submit"
                    icon={<StarOutlined />}
                  >
                    Create New Badge
                  </Button>
                </Link>
              </Flex>
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={true}>
              <Statistic
                title="Inactive Badges"
                value={passiveBadgeCount}
                valueStyle={{ color: "#cf1322" }}
                formatter={formatter}
                prefix={<ArrowDownOutlined />}
              />
            </Card>
          </Col>
        </Row>
        
        <Row gutter={[16, 16]} className="badge-list-row">
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card bordered={true}>
            <BadgeList></BadgeList>
            </Card>
          </Col>
        </Row>

      </Flex>
    </div>
  );
};

export default Dashboard;
