// MainLayout.js
import React, { useState } from "react";
import { Layout, Menu, Image, Switch, Dropdown, message, Flex } from "antd";
import {
  HomeOutlined,
  UserOutlined,
  BulbOutlined,
  StarOutlined,
  LogoutOutlined,
  MessageOutlined,
  CheckCircleOutlined,
  AppstoreAddOutlined,
  MobileOutlined,
  AlertOutlined
} from "@ant-design/icons";
import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import BadgeEditor from "../pages/badgeEditor";
import CategoryManagement from "../pages/categoryManagement";
import Logo from "../assets/img/logo.svg";
import UserManagement from "../pages/userManagement";
import LandingEditor from "../pages/landingEditor";
import BadgePreview from "../pages/badgePreview";
import BadgeEdit from "../pages/badgeEdit";
import ChecklistPage from "../pages/checklistPage";
import InfoMessage from "../pages/infoMessage";

const MainLayout = () => {
  const [darkMode, setDarkMode] = useState(false);
  const { Header, Content, Footer, Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const handleDarkModeChange = (checked) => {
    setDarkMode(checked);
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const userGroups = JSON.parse(localStorage.getItem("userGroups") || "[]");
  const isAdmin = userGroups.includes("admin");

  const menuItems = [
    getItem("Badges", "dashboard", <HomeOutlined />),
    getItem(
      "Create",
      "grp",
      null,
      [
        getItem("Badge", "badge-editor", <StarOutlined />),
        getItem("Landing Page", "landing-editor", <MobileOutlined />),

      ],
      "group"
    ),
    getItem(
      "Management",
      "grp",
      null,
      [
        getItem("Categories", "category-management", <AppstoreAddOutlined />),
        getItem("Checklists", "checklist-management", <CheckCircleOutlined />),
        getItem("Info Message", "info-message-management", <AlertOutlined />),
        ...(isAdmin
          ? [getItem("Users", "user-management", <UserOutlined />)]
          : []),

      ],

      "group"
    ),
  ];
  const items = [
    /*     {
      label: "Profile",
      key: "profile",
      icon: <UserOutlined />,
    }, */
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];
  const handleMenuClick = (e) => {
    if (e.key === "logout") {
      localStorage.clear();
      navigate("/login");
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="demo-logo-vertical">
          <Image src={Logo} width={"200"} preview={false} />
        </div>
        <Menu
          theme="dark"
          onClick={({ key }) => {
            navigate(key);
          }}
          mode="inline"
          items={menuItems}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            background: darkMode ? "#001529" : "#fff",
            margin: "10px 16px",
            borderRadius: "10px",
          }}
        >
          <Flex align="center" justify="flex-end" gap="small">
            <div>
              <Switch
                checked={darkMode}
                onChange={handleDarkModeChange}
                checkedChildren={<BulbOutlined />}
                unCheckedChildren={<BulbOutlined />}
                style={{ marginLeft: "8px", visibility: "collapse" }}
              />
            </div>

            <div>
              <Dropdown.Button
                menu={menuProps}
                placement="bottom"
                icon={<UserOutlined />}
              >
                {localStorage.getItem("userEmail")}
              </Dropdown.Button>
            </div>
          </Flex>
        </Header>
        <Content
          style={{
            margin: "0 16px",
            borderRadius: "10px",
            padding: "0 10px",
            background: "#fff",
          }}
        >
          <Routes>
            <Route path="/dashboard" element={<Dashboard />}>
              {" "}
            </Route>
            <Route path="/badge-editor" element={<BadgeEditor />}>
              {" "}
            </Route>
            <Route path="/landing-editor" element={<LandingEditor />}>
              {" "}
            </Route>
            <Route path="/category-management" element={<CategoryManagement />}>
              {" "}
            </Route>
            <Route path="/info-message-management" element={<InfoMessage />}>
              {" "}
            </Route>
            <Route path="/user-management" element={<UserManagement />}>
              {" "}
            </Route>
            <Route path="/checklist-management" element={<ChecklistPage />}>
              {" "}
            </Route>
            <Route path="/badge-preview/:id" element={<BadgePreview />}>
              {" "}
            </Route>
            <Route path="/badge-edit/:id" element={<BadgeEdit />}>
              {" "}
            </Route>
            <Route path="/badge-preview/:id" element={<BadgePreview />}>
              {" "}
            </Route>
          </Routes>
        </Content>
        <Footer
          style={{
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          Orlando Magic Badge App ©{new Date().getFullYear()} Powered by
          BlueCloud
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
