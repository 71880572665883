import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useState, useEffect } from 'react';
import { getParamsApi, putApi } from "../services/api.jsx";
import Swal from "sweetalert2";
import { Table, Image, Badge } from 'antd';

const SortableTable = ({ slides }) => {
    const [categoryBadges, setCategoryBadges] = useState("");
    const [loading, setLoading] = useState(false);

    const modifyDataSource = (slides) => {
        return slides.map((slide, index) => ({
            ...slide,
            key: slide.id || `slide-${index}`
        }));
    };
    const columns = [
        {
            key: 'sort',
        },
        {
            title: 'Order',
            dataIndex: 'categoryOrder',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Image',
            dataIndex: 'imageURL',
            render: imageURL => <Image width={50} src={imageURL} alt="badge" />
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            render: isActive => (
                <Badge status={isActive ? 'success' : 'error'} text={isActive ? 'Active' : 'Inactive'} />
            ),
        },
    ];
    const Row = ({ children, ...props }) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key'],
        });
        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                },
            ),
            transition,
            ...(isDragging
                ? {
                    position: 'relative',
                    zIndex: 9999,
                }
                : {}),
        };
        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React.Children.map(children, (child) => {
                    if (child.key === 'sort') {
                        return React.cloneElement(child, {
                            children: (
                                <MenuOutlined
                                    ref={setActivatorNodeRef}
                                    style={{
                                        touchAction: 'none',
                                        cursor: 'move',
                                    }}
                                    {...listeners}
                                />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };
    const getCategoryBadges = (categoryId) => {
        setLoading(true);
        const params = { categoryId: categoryId };
        getParamsApi(`/badge/category/${categoryId}`, params)
            .then((response) => {
                console.log("Badges:", response);
                setCategoryBadges(response);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching badgess:", error);
                setLoading(false);
            });
    };
    const updateCategoryOrder = (sortedArray) => {
        putApi("/badge/order", sortedArray)
            .then((data) => {
                console.log("order updated successfully:", data);
                Swal.fire({
                    title: "Success!",
                    text: "order updated successfully",
                    icon: "success",
                });
                getCategoryBadges();
            })
            .catch((error) => {
                console.error("Error updating order:", error);
                let errorMessage = "An error occurred while updating the order";
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    errorMessage = error.response.data.message;
                }
                Swal.fire({
                    title: "Error!",
                    text: errorMessage,
                    icon: "error",
                });
            });
    };
    const updatedSlides = modifyDataSource(slides);

    const [dataSource, setDataSource] = useState(updatedSlides);
    useEffect(() => {
        setDataSource(updatedSlides);
    }, [slides]);
    const onDragEnd = async ({ active, over }) => {
        if (active.id !== over?.id) {
            const previous = [...dataSource];
            const activeIndex = previous.findIndex((item) => item.key === active.id);
            const overIndex = previous.findIndex((item) => item.key === over?.id);
            let newOrder = arrayMove(previous, activeIndex, overIndex);
            newOrder = newOrder.map((item, index) => ({
                ...item,
                categoryOrder: index + 1
            }));
            try {
                const response = await updateCategoryOrder(newOrder.map(item => ({
                    id: item.id,
                    categoryOrder: item.categoryOrder

                })));
                setDataSource(newOrder);
            } catch (error) {
                console.error('Failed to update order: ', error);
            }
        }
    };




    return (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
                items={dataSource.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
            >
                <Table
                    components={{
                        body: {
                            row: Row,
                        },
                    }}
                    rowKey="key"
                    columns={columns}
                    dataSource={dataSource}
                />
            </SortableContext>
        </DndContext>
    );
};
export default SortableTable;