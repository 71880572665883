import React, { useState, useEffect } from "react";
import {
    PlusCircleOutlined,
    DeleteOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {
    Breadcrumb,
    Form,
    Input,
    Button,
    Table,
    Modal,
    Image,
    Flex,
    Space,
    Popconfirm,
    Upload,
    ColorPicker
} from "antd";
import { getApi, postApi, putApi, deleteApi } from "../services/api.jsx";
import Swal from "sweetalert2";
import noImage from "../assets/img/noimage.png";

const InfoMessage = () => {
    const [loading, setLoading] = useState(false);
    const [infoModalTitle, setInfoModalTitle] = useState("Create New Info Message");
    const [infoModalAction, setInfoModalAction] = useState("Create");
    const [infoMessageList, setInfoMessageList] = useState([]);
    const [bottom, setBottom] = useState("bottomRight");
    const [isInfoModalVisible, setisInfoModalVisible] = useState(false);
    const [infoForm] = Form.useForm();
    const [backgroundImageURL, setBackgroundImageURL] = useState();
    const [infoBackgroundColor, setInfoBackgroundColor] = useState("");


    const [iconURL, setIconURL] = useState("");
    const columns = [
        {
            title: "Message",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Icon",
            dataIndex: "iconURL",
            key: "iconURL",
            render: (value, record) => (
                <>
                    {record.iconURL ? (
                        <Image
                            src={record.iconURL}
                            alt="Icon"
                            width={35}
                            height={35}
                            style={{
                                background: "radial-gradient(#3d5ed1, transparent)",
                                borderRadius: "50%",
                                width: "50px;",
                                padding: "5px",
                            }}
                        />
                    ) : (
                        <Image
                            src={noImage}
                            alt="Default Image"
                            width={35}
                            style={{ opacity: ".5" }}
                        />
                    )}
                </>
            ),
        },
        {
            title: "Background Image",
            dataIndex: "imageURL",
            key: "imageURL",
            render: (value, record) => (
                <>
                    {record.imageURL ? (
                        <Image src={record.imageURL} alt="Icon" width={50} />
                    ) : (
                        <Image
                            src={noImage}
                            alt="Default Image"
                            width={35}
                            style={{ opacity: ".5" }}
                        />
                    )}
                </>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        {/* <Button
                            type="dashed"
                            onClick={() => openInfoMessageModalOpen(record.id)}
                            icon={<EditOutlined />}
                        >
                            Edit
                        </Button> */}
                        <Popconfirm
                            title="Are you sure to delete this info message?"
                            onConfirm={() => deleteInfoMessage(record.id)}
                            okText="Yes"
                            cancelText="No"
                            zIndex={"9999"}
                        >
                            <Button type="dashed" danger icon={<DeleteOutlined />}></Button>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];
    const uploadImage = async ({ file, onSuccess, onError }) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            postApi("/file", formData, "multipart/form-data").then((data) => {
                console.log(data);
                onSuccess(data, file);
            });
            Swal.fire({
                title: "Success!",
                text: `${file.name} uploaded successfully`,
                icon: "success",
            });
        } catch (error) {
            onError(error);
            Swal.fire({
                title: "Error!",
                text: `${file.name} upload failed`,
                icon: "error",
            });
        }
    };
    const deleteInfoMessage = (id) => {
        setLoading(true);
        const params = { id: id };
        deleteApi(`/infoMessage/${id}`, params)
            .then(() => {
                getInfoMessageList();
                console.log("Info Message deleted successfully");
                Swal.fire({
                    title: "Success!",
                    text: "Info Message deleted successfully",
                    icon: "success",
                });
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error deleting info message:", error);
                let errorMessage = "An error occurred while deleting the info message";
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    errorMessage = error.response.data.message;
                }
                Swal.fire({
                    title: "Error!",
                    text: errorMessage,
                    icon: "error",
                });
                setLoading(false);
            });
    };

    const openInfoMessageModalOpen = (action, record) => {
        if (action === "create") {
            setInfoModalTitle("Create New Info Message");
            setInfoModalAction("Create");
            infoForm.resetFields();
        } else if (action === "update") {
            setInfoModalTitle("Edit Info Message");
            setInfoModalAction("Update");
            infoForm.setFieldsValue({
                text: record.text,
                iconSvg: record.iconSvg,
                backgroundImage: record.backgroundImage,
            });
        }
        setisInfoModalVisible(true);
    };

    const getInfoMessageList = () => {
        setLoading(true);
        getApi("/infoMessage/list").then((infoMessageList) => {
            setInfoMessageList(infoMessageList);
            setLoading(false);
        });
    };

    const createInfoMessage = (values) => {
        try {
            const requestBody = {
                ...values,
                iconURL: iconURL,
                imageURL: backgroundImageURL
            };
            postApi("/infoMessage", requestBody)
                .then((data) => {
                    console.log("Info Message created successfully:", data);
                    setisInfoModalVisible(false);
                    Swal.fire({
                        title: "Success!",
                        text: "Info Message created successfully",
                        icon: "success",
                    });
                    getInfoMessageList();
                })
                .catch((error) => {
                    console.error("Error creating info Messsage:", error);
                    let errorMessage = "An error occurred while creating the info";
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    ) {
                        errorMessage = error.response.data.message;
                    }
                    Swal.fire({
                        title: "Error!",
                        text: errorMessage,
                        icon: "error",
                    });
                });
        } catch (error) {
            console.error("Error creating info Message:", error);
            Swal.fire({
                title: "Error!",
                text: "An error occurred while creating the info message",
                icon: "error",
            });
        }
    };

    const updateInfoMessage = (values) => {
        const requestBody = {
            ...values,
            groups: values.groups,
        };
        putApi("/infoMessage", requestBody)
            .then((data) => {
                console.log("Info message updated successfully:", data);
                setisInfoModalVisible(false);
                Swal.fire({
                    title: "Success!",
                    text: "Info message updated successfully",
                    icon: "success",
                });
                getInfoMessageList();
            })
            .catch((error) => {
                console.error("Error updating info message:", error);
                let errorMessage = "An error occurred while updating the info message";
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    errorMessage = error.response.data.message;
                }
                Swal.fire({
                    title: "Error!",
                    text: errorMessage,
                    icon: "error",
                });
            });
    };

    const s3BaseURL = process.env.REACT_APP_S3_BASE_URL;
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleBackgroundImageChange = (info) => {
        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (url) => {
                let backgroundImageURL = s3BaseURL + info.file.originFileObj.name;
                console.log("backgroundImageURL:", backgroundImageURL)
                setBackgroundImageURL(backgroundImageURL);
            });
        }
    };
    const handleIconChange = (info) => {
        console.log("info:", info)
        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (url) => {
                let iconUrl = s3BaseURL + info.file.originFileObj.name;
                setIconURL(iconUrl);
            });
        }
    };
    const handleColorChange = (color, setter) => {
        const hexValue = typeof color === "string" ? color : color?.toHexString();
        setter(hexValue);
    };
    const removeBackgroundImage = () => {
        setBackgroundImageURL('');
    };

    useEffect(() => {
        getInfoMessageList();
    }, []);

    return (
        <>
            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner" />
                </div>
            )}
            <Flex
                gap="middle"
                align="center"
                justify="space-between"
                className="page-header"
            >
                <h2>Info Message Management</h2>
                <Breadcrumb
                    items={[
                        {
                            title: "Home",
                        },
                        {
                            title: <a href="">Info Message Management</a>,
                        },
                    ]}
                />
            </Flex>
            <Flex vertical align="space-end">
                <Button
                    shape="round"
                    className="badge-btn-dark"
                    style={{ width: "230px", marginTop: "20px", marginBottom: "10px" }}
                    onClick={() => openInfoMessageModalOpen("create")}
                    icon={<PlusCircleOutlined />}
                >
                    Create New Info Message
                </Button>

                <Table
                    pagination={{
                        position: [bottom],
                    }}
                    bordered={true}
                    rowKey="key"
                    columns={columns}
                    dataSource={infoMessageList}
                />
                <Modal
                    title={infoModalTitle}
                    open={isInfoModalVisible}
                    onCancel={() => setisInfoModalVisible(false)}
                    onOk={() => infoForm.submit()}
                    okText={infoModalAction === "Create" ? "Create" : "Update"}
                >
                    <Form
                        form={infoForm}
                        onFinish={infoModalAction === "Create" ? createInfoMessage : updateInfoMessage}
                        scrollToFirstError
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Form.Item
                            name="description"
                            label="Message"
                            rules={[{ required: true, message: "Please input first name!" }]}
                        >
                            <Input placeholder="Input Message" />
                        </Form.Item>
                        <Form.Item
                            name="backgroundColor"
                            className="ms-10"
                            label="Background Color"
                            getValueFromEvent={(color) => {
                                return "#" + color.toHex();
                            }}
                            rules={[
                                {
                                    required: false,
                                    message: "select background color!",
                                },
                            ]}
                        >
                            <ColorPicker
                                showText
                                defaultValue="#1E222B"
                                // value={backgroundColor}
                                onChange={(color) =>
                                    handleColorChange(color, setInfoBackgroundColor)
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="ms-5"
                            name="imageURL"
                            label="Background Image"
                            rules={[
                                { required: false, message: "Please select header icon!" },
                            ]}
                        >
                            <Upload
                                listType="picture-card"
                                onRemove="true"
                                name="imageURL"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={uploadImage}
                                beforeUpload={false}
                                onChange={handleBackgroundImageChange}
                            >
                                {backgroundImageURL ? (
                                    <>
                                        <Image
                                            preview={false}
                                            src={backgroundImageURL}
                                            alt="Icon"
                                            width={50}
                                        />
                                        <Button danger size="small" icon={<DeleteOutlined />} onClick={removeBackgroundImage} style={{ marginLeft: 8 }}></Button></>


                                ) : (
                                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                                )}
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            className="ms-5"
                            name="iconURL"
                            label="Icon"
                            rules={[
                                { required: false, message: "Please select header icon!" },
                            ]}
                        >
                            <Upload
                                listType="text"
                                onRemove="true"
                                name="iconURL"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={uploadImage}
                                beforeUpload={false}
                                onChange={handleIconChange}
                            >
                                {iconURL ? (
                                    <div >
                                        <Image src={iconURL} alt="Icon" width={35} preview={false} style={{
                                            background: "radial-gradient(#3d5ed1, transparent)",
                                            borderRadius: "50%",
                                            width: "50px;",
                                            padding: "5px",
                                        }} />
                                    </div>

                                ) : (
                                    <Button icon={<UploadOutlined />}>Upload SVG</Button>
                                )}
                            </Upload>
                        </Form.Item>





                    </Form>
                </Modal>

            </Flex>
        </>
    );
};

export default InfoMessage;
