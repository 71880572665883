import { createSlice } from '@reduxjs/toolkit';

const imageSlice = createSlice({
  name: 'image',
  initialState: { fileList: [] },
  reducers: {
    setFileList: {
      reducer(state, action) {
        state.fileList = action.payload;
      },
      prepare(payload) {
        return { payload: payload.map(item => ({ ...item, lastModifiedDate: item.lastModifiedDate.toJSON() })) };
      },
    },
  },
});

export const { setFileList } = imageSlice.actions;
export const selectFileList = (state) => state.image.fileList;

export default imageSlice.reducer;
