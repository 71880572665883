import React, { useRef, useState, useEffect } from "react";
import {
  EditOutlined,
  KeyOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Form,
  Input,
  message,
  Button,
  Badge,
  Table,
  Select,
  Modal,
  Switch,
  Flex,
  Row,
  Col,
  Space,
  Tooltip,
  Tag
} from "antd";
import { getApi, postApi, putApi } from "../services/api.jsx";
import Swal from "sweetalert2";

const UserManagement = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [userForm] = Form.useForm();
  const [searchedText, setSearchedText] = useState("");
  const [resetPasswordForm] = Form.useForm();
  const { Option } = Select;
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [userModalTitle, setUserModalTitle] = useState("Create New User");
  const [userModalAction, setUserModalAction] = useState("Create");
  const [userRecord, setUserRecord] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bottom, setBottom] = useState("bottomRight");
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [role, setRole] = useState('');

  const handleRoleChange = (value) => {
    setRole(value);
  };
  const getTooltipMessage = () => {
    if (role === 'admin') {
      return 'Can create/edit users or reset their passwords';
    } else if (role === 'employee') {
      return 'Cannot create/edit users or reset their passwords';
    } else {
      return '';
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const copyPasswordToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success("Password copied to clipboard!");
  };

  const columns = [
    {
      key: "Status",
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <Badge
          status={record.active ? "success" : "error"}
          text={record.active ? "Active" : "Inactive"}
        />
      ),
      sorter: (a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1),
      sortDirections: ['descend'],
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "firstName",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.firstName).toLowerCase().includes(value.toLowerCase()) ||
          String(record.username).toLowerCase().includes(value.toLowerCase()) ||
          String(record.email).toLowerCase().includes(value.toLowerCase()) ||
          (record.groups && record.groups.some(role => String(role).toLowerCase().includes(value.toLowerCase())))
        );
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "E-Mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "groups",
      key: "groups",
      render: (groups) => {
        const role = groups.includes('admin') ? 'admin' : 'employee';
        return (
          <Tag color={role === 'admin' ? 'magenta' : 'cyan'}>{role}</Tag>

        );
      },
      sorter: (a, b) => {
        const roleA = a.groups.includes('admin') ? 'admin' : 'employee';
        const roleB = b.groups.includes('admin') ? 'admin' : 'employee';
        return roleA.localeCompare(roleB);
      },
      sortDirections: ['descend', 'ascend'],
    },

    /*     {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {showPassword ? (
            <Input
              value={text}
              disabled
              style={{ marginRight: "8px", width: "100px" }}
            />
          ) : (
            <Input
              width={"80px"}
              value="********"
              disabled
              style={{ marginRight: "8px", width: "100px" }}
            />
          )}
          <Button
            type="text"
            icon={showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={togglePasswordVisibility}
          />
          <Button
            type="text"
            icon={<CopyOutlined />}
            onClick={() => copyPasswordToClipboard(text)}
          />
        </div>
      ),
    }, */
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="dashed"
            onClick={() => openUserModal("update", record)}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
          <Button
            type="dashed"
            danger
            onClick={() => openResetPasswordModal(record)}
            icon={<KeyOutlined />}
          >
            Reset Password
          </Button>
        </Space>
      ),
    },
  ];

  const generateRandomPassword = () => {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numberChars = "0123456789";
    const specialChars = ".,#@$!%*?&";

    const allChars = lowercaseChars + uppercaseChars + numberChars + specialChars;
    const passwordLength = 8;

    let password = "";

    password += uppercaseChars.charAt(Math.floor(Math.random() * uppercaseChars.length));
    password += lowercaseChars.charAt(Math.floor(Math.random() * lowercaseChars.length));
    password += numberChars.charAt(Math.floor(Math.random() * numberChars.length));
    password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

    for (let i = 4; i < passwordLength; i++) {
      password += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }

    password = password
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");

    return password;
  };


  const generatePassword = () => {
    const newPassword = generateRandomPassword();
    userForm.setFieldsValue({ password: newPassword });
  };

  const generateNewPassword = () => {
    const newPassword = generateRandomPassword();
    resetPasswordForm.setFieldsValue({ newPassword: newPassword });
  };

  const openResetPasswordModal = (record) => {
    resetPasswordForm.resetFields();
    setUserRecord(record);
    setIsResetPasswordModalOpen(true);
  };

  const resetPassword = (values) => {
    let data = {
      username: userRecord.username,
      newPassword: values.newPassword,
    };
    postApi("/auth/admin/setPassword", data)
      .then(() => {
        setIsResetPasswordModalOpen(false);
        Swal.fire({
          title: "Success!",
          text: "Password has been reset successfully",
          icon: "success",
        });
      })
      .catch((error) => {
        console.error("Error creating user:", error);
        let errorMessage = "An error occurred while reset password";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
        });
      });
  };

  const openUserModal = (action, record) => {
    if (action === "create") {
      setUserModalTitle("Create New User");
      setUserModalAction("Create");
      userForm.resetFields();
    } else if (action === "update") {
      setUserModalTitle("Edit User");
      setUserModalAction("Update");
      setUserRecord(record);
      userForm.setFieldsValue({
        name: record.name,
        email: record.email,
        role: record.role,
        username: record.username,
        active: record.active,
        password: record.password,
        groups: record.groups,
      });
    }
    setIsUserModalVisible(true);
  };

  const getUserList = () => {
    setLoading(true);
    getApi("/user").then((userList) => {
      setUserList(userList);
      setLoading(false);
    });
  };

  const createUser = (values) => {
    if (!values.name || !values.username || !values.email || !values.password || !values.groups) {
      message.error("Please fill out all required fields.");
      return;
    }
    const groupsArray = Array.isArray(values.groups) ? values.groups : [values.groups];
    const requestBody = {
      ...values,
      groups: groupsArray,
    };
    postApi("/user", requestBody)
      .then((data) => {
        console.log("User created successfully:", data);
        setIsUserModalVisible(false);
        Swal.fire({
          title: "Success!",
          text: "User created successfully",
          icon: "success",
        });
        getUserList();
      })
      .catch((error) => {
        console.error("Error creating user:", error);
        let errorMessage = "An error occurred while creating the user";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
        });
      });
  };


  const updateUser = (values) => {
    const requestBody = {
      ...values,
      groups: values.groups,
    };
    putApi("/user", requestBody)
      .then((data) => {
        console.log("User updated successfully:", data);
        setIsUserModalVisible(false);
        Swal.fire({
          title: "Success!",
          text: "User updated successfully",
          icon: "success",
        });
        getUserList();
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        let errorMessage = "An error occurred while updating the user";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
        });
      });
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner" />
        </div>
      )}
      <Flex
        gap="middle"
        align="center"
        justify="space-between"
        className="page-header"
      >
        <h2>User Management</h2>
        <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: <a href="">User Management</a>,
            },
          ]}
        />
      </Flex>
      <Flex vertical align="space-end">
        <Button
          shape="round"
          className="badge-btn-dark"
          style={{ width: "200px", marginTop: "20px", marginBottom: "10px" }}
          onClick={() => openUserModal("create")}
          icon={<PlusCircleOutlined />}
        >
          Create New User
        </Button>
        <Row>
          <Col span={8}>
            <Input.Search
              placeholder="Search.."
              onSearch={(value) => {
                setSearchedText(value);
              }}
              onChange={(e) => {
                setSearchedText(e.target.value);
              }}
              style={{ marginBottom: 8 }}
            />
          </Col>
        </Row>
        <Table
          pagination={{
            position: [bottom],
          }}
          bordered={true}
          rowKey="key"
          columns={columns}
          dataSource={userList}
        />
        <Modal
          title={userModalTitle}
          open={isUserModalVisible}
          onCancel={() => setIsUserModalVisible(false)}
          onOk={() => userForm.submit()}
          okText={userModalAction === "Create" ? "Create" : "Update"}
        >
          <Form
            form={userForm}
            onFinish={userModalAction === "Create" ? createUser : updateUser}
            scrollToFirstError
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              name="name"
              label="Full Name"
              rules={[{ required: true, message: "Please input first name!" }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true, message: "Please input first name!" }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter a vaild email address!",
                },
                {
                  pattern:
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="groups"
              label="Role"
              rules={[{ required: true, message: "Please select role!" }]}>
              <Tooltip title={role ? getTooltipMessage() : ''}>
                <Select
                  placeholder="Select a role"
                  style={{ minWidth: "200px" }}
                  onChange={(value) => {
                    handleRoleChange(value);
                    userForm.setFieldsValue({ groups: value });
                  }}>
                  {[
                    { value: "admin", label: "admin" },
                    { value: "employee", label: "Employee" },
                  ].map((group) => (
                    <Option key={group.value} value={group.value} label={group.label}>
                      {group.label}
                    </Option>
                  ))}
                </Select>
              </Tooltip>
            </Form.Item>



            {userModalAction === "Create" ? (
              ""
            ) : (
              <Form.Item
                name="active"
                label="Status"
                rules={[{ required: true, message: "Please input status!" }]}
              >
                <Switch
                  defaultChecked="Active"
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  style={{ marginLeft: "8px" }}
                />
              </Form.Item>
            )}


            {userModalAction === "Create" && (
              <Flex>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid password!",
                    },
                    {
                      pattern:
                        /^(?:[.,#@$!%*?&]|\d|[A-Z]|[a-z])+$/,
                      message:
                        "Password should contain at least 8 character with at least one of these symbols [.,#@$!%*?&], one uppercase, one lower case letter and a number",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Button
                  onClick={generatePassword}
                  className=" ms-5"
                  style={{ marginTop: "30px" }}

                  danger
                  icon={<KeyOutlined />}
                >
                  Generate
                </Button>
              </Flex>
            )}
          </Form>
        </Modal>
        <Modal
          title={"Reset Password"}
          open={isResetPasswordModalOpen}
          onCancel={() => setIsResetPasswordModalOpen(false)}
          onOk={() => resetPasswordForm.submit()}
          okText={"Reset Password"}
        >
          <Form
            form={resetPasswordForm}
            onFinish={resetPassword}
            scrollToFirstError
            layout="vertical"
            autoComplete="off"
          >
            <Flex>
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid password!",
                  },
                  {
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.,#@$!%*?&])[A-Za-z\d.,#@$!%*?&]{8,}$/i,
                    message:
                      "Password should contain at least 8 character with at least one of these symbols [.,#@$!%*?&], one uppercase, one lower case letter and a number",
                  },
                ]}
              >
                <Input.Password placeholder="New Password" />
              </Form.Item>
              <Button
                onClick={generateNewPassword}
                className=" ms-5"
                style={{ marginTop: "22px" }}
                shape="round"
                danger
                icon={<KeyOutlined />}
              >
                Generate
              </Button>
            </Flex>
          </Form>
        </Modal>
      </Flex>
    </>
  );
};

export default UserManagement;
