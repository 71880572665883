import React, { useRef, useState, useEffect } from "react";
import {
  EditOutlined,
  KeyOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Form,
  Input,
  message,
  Button,
  Badge,
  Table,
  Select,
  Modal,
  Switch,
  Flex,
  Row,
  Col,
  Image,
  Space,
  Popconfirm,
} from "antd";
import Highlighter from "react-highlight-words";
import { deleteApi, getApi, postApi } from "../services/api.jsx";
import Swal from "sweetalert2";
import noImage from "../assets/img/noimage.png";
import ChecklistModal from "../components/checklistModal.jsx";

const ChecklistPage = () => {
  const [searchedText, setSearchedText] = useState("");
  const [checklists, setChecklists] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [record, setRecord] = useState();
  const [viewMode, setViewMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [badgeList, setBadgeList] = useState([]);

  useEffect(() => {
    fetchChecklists();
    fetchBadgeList();
  }, []);

  const openModal = (record, viewMode) => {
    setRecord(record);
    setIsModalVisible(true);
    setViewMode(viewMode);
  };

  const closeModal = () => {
    fetchChecklists();
    setIsModalVisible(false);
    setRecord();
    setViewMode(false);
  };

  const expandable = {
    expandedRowRender: (record) => (
      <ul>
        {record.items.map((item) => (
          <li key={item.id}>{item.description}</li>
        ))}
      </ul>
    ),
  };

  const fetchChecklists = () => {
    setLoading(true);
    getApi("/checklist/list")
      .then((checklists) => {
        setChecklists(checklists);
      })
      .catch((ex) => {
        Swal.fire({
          title: "Error!",
          text: "Error fetching checklist",
          ex,
          icon: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchBadgeList = () => {
    setLoading(true);
    getApi("/badge/list")
      .then((badgeList) => {
        setBadgeList(badgeList);
      })
      .catch((ex) => {
        Swal.fire({
          title: "Error!",
          text: "Error fetching badge",
          ex,
          icon: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteChecklist = (record) => {
    setLoading(true);
    const params = { id: record.id };
    deleteApi(`/checklist/${record.id}`, params)
      .then(() => {
        fetchChecklists();
        Swal.fire({
          title: "Success!",
          text: "Checklist has been deleted successfully",
          icon: "success",
        });
      })
      .catch((error) => {
        console.error("Error deleting checklist:", error);
        let errorMessage = "An error occurred while deleting the checklist";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Icon SVG",
      dataIndex: "iconURL",
      key: "iconURL",
      render: (value, record) => (
        <>
          {record.iconURL ? (
            <Image
              src={record.iconURL}
              alt="Icon"
              width={35}
              style={{
                background: "radial-gradient(#3d5ed1, transparent)",
                borderRadius: "50%",
                width: "50px;",
                padding: "5px",
              }}
            />
          ) : (
            <Image
              src={noImage}
              alt="Default Image"
              width={35}
              style={{ opacity: ".5" }}
            />
          )}
        </>
      ),
    },

    {
      title: "Checklist Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="dashed"
            onClick={() => openModal(record, true)}
            icon={<EyeOutlined />}
          >
            View
          </Button>
          <Button
            type="dashed"
            onClick={() => openModal(record, false)}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this checklist?"
            onConfirm={() => deleteChecklist(record)}
            okText="Yes"
            cancelText="No"
            zIndex={"9999"}
          >
            <Button type="dashed" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner" />
        </div>
      )}
      <Flex
        gap="middle"
        align="center"
        justify="space-between"
        className="page-header"
      >
        <h2>Checklist Management</h2>
        <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: <a href="">Checklist Management</a>,
            },
          ]}
        />
      </Flex>
      <Flex vertical align="space-end">
        <Button
          shape="round"
          className="badge-btn-dark"
          style={{ width: "200px", marginTop: "20px", marginBottom: "10px" }}
          onClick={() => openModal()}
          icon={<PlusCircleOutlined />}
        >
          Create Checklist
        </Button>
        <Row>
          <Col span={6}>
            <Input.Search
              placeholder="Search.."
              onSearch={(value) => {
                setSearchedText(value);
              }}
              onChange={(e) => {
                setSearchedText(e.target.value);
              }}
              style={{ marginBottom: 8 }}
            />
          </Col>
        </Row>
        <Table
          bordered={true}
          rowKey="key"
          columns={columns}
          dataSource={checklists}
        />
        {isModalVisible && (
          <ChecklistModal
            record={record}
            closeModal={closeModal}
            viewMode={viewMode}
            badgeList={badgeList}
          />
        )}
      </Flex>
    </div>
  );
};

export default ChecklistPage;
